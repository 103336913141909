import { Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import TablePagination from '@material-ui/core/TablePagination';
import Tabs from '@material-ui/core/Tabs';
import React, { useEffect, useState } from 'react';
import { HttpError, HttpSuccess } from '../../snackbars';
import { useStyles } from '../localOfficer/drawer';

import { createSelector } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { adminGetInvoiceReceiptsAction, adminGetPackagesReceiptsAction } from '../../../actions/AdminActions';
import { useAppSelector } from '../../../store/store';
import ReceiptsTable from '../../common/tables/receipts';
import '../../dashboard/Dashboard.scss';
import '../../dashboard/invoices/Invoices.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

function a11yProps(index) {
	return {
		id: `wrapped-tab-${index}`,
		'aria-controls': `wrapped-tabpanel-${index}`,
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
			{...other}
		>
			{value === index && (
				//   <Box p={3}>
				<Typography>{children}</Typography>
				//   </Box>
			)}
		</div>
	);
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         borderColor: "green",
//     },
// }));

const OfficerReceipts = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.dash.userInfoFetched,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
			state => state.dash.receipts,
			state => state.dash.receiptsCount,
			state => state.dash.packageReceipts,
			state => state.dash.packageReceiptsCount,
		],
		(userInfoFetched, successMsg, errorMsg, receipts, receiptsCount, packageReceipts, packageReceiptsCount) => {
			return {
				userInfoFetched,
				successMsg,
				errorMsg,
				receipts,
				receiptsCount,
				packageReceipts,
				packageReceiptsCount,
			};
		}
	);

	const { userInfoFetched, successMsg, errorMsg, receipts, receiptsCount, packageReceipts, packageReceiptsCount } =
		useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		receipts: [],
		receipt: {},
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
		page: 0,
		rowsPerPage: 10,
		filterLimit: 10,
		filterSkip: 0,
		receiptsCount: 0,
		paymentLimit: 0,
		paymentSkip: 0,
		packageReceipts: [],
		packageReceiptsCount: 0,
	});

	useEffect(
		() => {
			const { filterLimit, filterSkip, paymentLimit, paymentSkip } = state;
			// props.getUserInfo();
			dispatch(adminGetInvoiceReceiptsAction(filterLimit, filterSkip));
			dispatch(adminGetPackagesReceiptsAction(paymentLimit, paymentSkip));
		},
		// eslint-disable-next-line
		[state.filterSkip, state.filterLimit]
	);

	// const receipts = useSelector(state => state.dash.receipts)

	useEffect(
		() => {
			setState({ ...state, receipts, receiptsCount, packageReceipts, packageReceiptsCount });
		},
		// eslint-disable-next-line
		[receipts, receiptsCount, packageReceipts, packageReceiptsCount]
	);

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { filterLimit, filterSkip } = state;
			dispatch(adminGetInvoiceReceiptsAction(filterLimit, filterSkip));
		});
	};

	const [value, setValue] = React.useState('Invoice');

	// if (!userInfoFetched) {
	//     return (
	//         <div className='linear_progress'>
	//             <LinearProgress />
	//         </div>
	//     );
	// }

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<Paper className='clean_paper table-wrap'>
							<Typography style={{ fontSize: '1.6em' }}>Receipts</Typography>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className='table-wrap'>
							<Tabs
								TabIndicatorProps={{
									style: {
										backgroundColor: '#F57E29',
									},
								}}
								value={value}
								onChange={handleChange}
								aria-label='wrapped labe'
								className='header-bg text-neutral-800'
							>
								<Tab value='Invoice' label='The Shipping Receipts' {...a11yProps('one')} />
								<Tab
									value='Shipments'
									className='text-red-500'
									label='Package Protection Receipts'
									{...a11yProps('shipments')}
								/>
							</Tabs>
							<TabPanel value={value} index='Invoice'>
								<ReceiptsTable receipts={state.receipts} shipment={false} />
								<TablePagination
									component='div'
									count={state.receiptsCount || 0}
									page={state.page}
									onPageChange={handleChangePage}
									rowsPerPage={state.rowsPerPage}
									rowsPerPageOptions={[10, 25, 50, 100]}
								/>
							</TabPanel>
							<TabPanel value={value} index='Shipments'>
								<ReceiptsTable receipts={state.packageReceipts} shipment={true} />
								<TablePagination
									component='div'
									count={state.packageReceiptsCount || 0}
									page={state.page}
									onPageChange={handleChangePage}
									rowsPerPage={state.rowsPerPage}
									rowsPerPageOptions={[10, 25, 50, 100]}
								/>
							</TabPanel>
						</Paper>
					</Grid>
				</Grid>
			</Paper>
			{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
		</div>
	);
};

export default OfficerReceipts;
