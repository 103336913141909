import { configureStore } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import rootReducer from '../reducers/rootReducer';

export const useAppSelector = useSelector;

const store = configureStore({
	reducer: rootReducer,
	// middleware: (getDefaultMiddleware) => getDefaultMiddleware({
	// 	immutableCheck: false,
	// 	serializableCheck: false,
	//   })
});

export default store;
