import {
	Button,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	Input,
	InputLabel,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ClearOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
	dashAddAddressAction,
	dashGetUserInfoAction,
	dashRemoveAddressAction,
	dashUpdateUserProfileAction,
} from '../../../actions/DashAction';
import { Regions } from '../../../config';
import { HttpError, HttpSuccess } from '../../snackbars';

import '../Dashboard.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const Editprofile = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		invoices: [],
		notificationsDialogOpen: false,
		addAddressDialogOpen: false,
		mobileScreen: mql.matches,
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
		secondary_email: '',
		addressName: '',
		addressTown: '',
		addressStreet: '',
		addressApartmentName: '',
		addressOtherLocationInfo: '',
		emptyProfile: '',
	});

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
		},
		// eslint-disable-next-line
		[]
	);

	const { userInfoFetched, userInfo, userAddress, userProfileInfoUpdated, addressAdded, errorMsg, successMsg } =
		useSelector(state => ({
			userInfoFetched: state.dash.userInfoFetched,
			userInfo: state.dash.userInfo,
			userAddress: state.dash.userAddress,
			userProfileInfoUpdated: state.dash.userProfileInfoUpdated,
			addressAdded: state.dash.addressAdded,
			errorMsg: state.dash.errorMsg,
			successMsg: state.dash.successMsg,
		}));

	useEffect(
		() => {
			if (userInfoFetched) {
				setState({
					...state,
					firstName: userInfo && userInfo.client.firstName,
					lastName: userInfo && userInfo.client.lastName,
					phoneNumber: userInfo && userInfo.client.phoneNumber,
				});
				if (
					userInfo &&
					(userInfo.client.firstName === null ||
						userInfo.client.lastName === null ||
						userInfo.client.phoneNumber === null)
				) {
					setState({ ...state, emptyProfile: '* Please update profile to proceed' });
				}
			}

			if (userProfileInfoUpdated) {
				navigate('/dashboard');
			}
			if (addressAdded) {
				dispatch(dashGetUserInfoAction());
			}
		},
		// eslint-disable-next-line
		[userInfoFetched, userInfo, userProfileInfoUpdated, addressAdded]
	);

	const handleFormInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};

	const onInputChange = event => {
		setState({
			...state,
			[event.target.name]: event.target.value,
		});
	};
	const onAddAddress = event => {
		event.preventDefault();

		const { addressName, addressTown, addressStreet, addressApartmentName, addressOtherLocationInfo } = state;
		dispatch(
			dashAddAddressAction(
				addressName,
				addressTown,
				addressStreet,
				addressApartmentName,
				addressOtherLocationInfo
			)
		);
		setState({
			...state,
			addressName: '',
			addressTown: '',
			addressStreet: '',
			addressApartmentName: '',
			addressOtherLocationInfo: '',
			addAddressDialogOpen: false,
		});
	};
	const onUpdateUserInfo = event => {
		event.preventDefault();

		const profile = {
			firstName: state.firstName,
			lastName: state.lastName,
			phoneNumber: state.phoneNumber,
		};

		dispatch(dashUpdateUserProfileAction(profile));
	};

	// const onUseWarehouseAddress = () => {
	// 	dispatch(dashAddAddressAction(
	// 		'Self collection at the warehouse',
	// 		'Nairobi', 'Argwing Kodhek Road',
	// 		'VituMob Company Limited', 'Metropolitan Court, Hse No. 8'
	// 	))
	// }

	const { mobileScreen } = state;

	if (!userInfoFetched) {
		return (
			<div className='linear_progress'>
				<LinearProgress />
			</div>
		);
	} else {
		return (
			<div>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '10vh',
					}}
				>
					<div
						style={{
							// marginTop: 36,
							padding: 24,
						}}
					>
						<h3 className='text-red-500'>Please complete your profile and add at least one address to proceed </h3>
					</div>
					<Grid container justify='center' spacing={0}>
						<Grid item xs={mobileScreen ? 12 : 6}>
							<Card style={{ margin: '6%', borderRadius: '12px', padding: '2%' }}>
								{state.emptyProfile && (
									<small style={{ color: 'orangered', padding: '2% 0 0 2%' }}>
										{state.emptyProfile}
									</small>
								)}
								<form onSubmit={onUpdateUserInfo}>
									<CardHeader title='Account Details' />
									<CardContent>
										<InputLabel>First Name</InputLabel>
										<Input
											value={state.firstName || ''}
											name='firstName'
											onChange={handleFormInputChange}
											style={{ width: '100%' }}
										/>
									</CardContent>
									<CardContent>
										<InputLabel>Last Name</InputLabel>
										<Input
											value={state.lastName || ''}
											name='lastName'
											onChange={handleFormInputChange}
											style={{ width: '100%' }}
										/>
									</CardContent>
									{/* <CardContent>
											<InputLabel>Email</InputLabel>
											<Input value={state.email || ''} name="email"
												onChange={handleFormInputChange} style={{width: '100%'}} />
										</CardContent> */}
									{/* <CardContent>
											<InputLabel>Secondary Email</InputLabel>
											<Input value={state.secondary_email || ''} name="secondary_email"
												onChange={handleFormInputChange} style={{width: '100%'}} />
										</CardContent> */}
									<CardContent>
										<InputLabel>Phone Number</InputLabel>
										<Input
											value={state.phoneNumber || ''}
											name='phoneNumber'
											onChange={handleFormInputChange}
											style={{ width: '100%' }}
										/>
									</CardContent>
									<CardContent style={{ textAlign: 'right' }}>
										<Typography className='align_icons'>
											<Button
												type='submit'
												style={{
													background: '#232C39',
													color: 'white',
												}}
											>
												Update
											</Button>
											&nbsp;&nbsp;&nbsp;
											<Link
												to='/dashboard/changepassword'
												className='align_icons'
												style={{ textDecoration: 'none', color: 'orangered' }}
											>
												<ClearOutlined />
												&nbsp;Change Password
											</Link>
										</Typography>
									</CardContent>
								</form>
							</Card>
						</Grid>
						<Grid item xs={mobileScreen ? 12 : 6}>
							<Card style={{ margin: '6%', borderRadius: '12px' }}>
								<CardHeader title='Delivery addresses' />
								<CardContent>
									{userAddress &&
										userAddress.map(address => {
											return (
												<Paper key={address.id} className='clean_paper'>
													<Typography>
														<b>Name:&nbsp;</b>
														{address.addressName}
													</Typography>
													<Typography>
														<b>Town:&nbsp;</b>
														{address.addressTown}
													</Typography>
													<Typography>
														<b>Street:&nbsp;</b>
														{address.addressStreet}
													</Typography>
													<Typography>
														<b>Appartment / building:&nbsp;</b>
														{address.addressApartmentName}
													</Typography>
													<Typography>
														<b>Other location information:&nbsp;</b>
														{address.addressOtherLocationInfo}
													</Typography>
													<Button
														onClick={() => dispatch(dashRemoveAddressAction(address.id))}
														style={{ color: 'orangered' }}
													>
														Remove
													</Button>
													<Divider />
												</Paper>
											);
										})}
								</CardContent>
								<CardContent>
									<Button
										onClick={() => setState({ ...state, addAddressDialogOpen: true })}
										style={{ background: '#232C39', color: 'white', textTransform: 'none' }}
									>
										Add delivery address
									</Button>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Paper>
				{/* Add address modal */}
				<Dialog
					open={state.addAddressDialogOpen}
					onClose={() => setState({ ...state, addAddressDialogOpen: false })}
				>
					<DialogTitle>Add address</DialogTitle>
					<DialogContent>
						<form onSubmit={onAddAddress}>
							<TextField
								value={state.addressName}
								required
								name='addressName'
								onChange={onInputChange}
								placeholder='Address name *'
								style={{ margin: '3%', marginLeft: '0' }}
								fullWidth
								helperText='E.g. Home address / Office address'
							/>
							<FormControl fullWidth>
								<InputLabel id='new_address_select'>Town or City *</InputLabel>
								<Select
									labelId='new_address_select'
									value={state.addressTown}
									name='addressTown'
									onChange={onInputChange}
									style={{ margin: '4%', marginLeft: '0' }}
								>
									{Regions.map(region => {
										return (
											<MenuItem key={Math.random()} value={region}>
												{region}
											</MenuItem>
										);
									})}
								</Select>
							</FormControl>
							<TextField
								value={state.addressStreet}
								required
								name='addressStreet'
								onChange={onInputChange}
								placeholder='Street *'
								fullWidth
								style={{ margin: '3%', marginLeft: '0' }}
							/>
							<TextField
								value={state.addressApartmentName}
								required
								name='addressApartmentName'
								onChange={onInputChange}
								placeholder='Apartment / Building name / House number *'
								fullWidth
								style={{ margin: '3%', marginLeft: '0' }}
							/>
							<TextField
								value={state.addressOtherLocationInfo}
								name='addressOtherLocationInfo'
								onChange={onInputChange}
								placeholder='Other address information'
								fullWidth
								style={{ margin: '3%', marginLeft: '0' }}
							/>
							<Button type='submit' color='primary'>
								Add address
							</Button>
						</form>
					</DialogContent>
				</Dialog>
				{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
			</div>
		);
	}
};

export default Editprofile;
