import { ExpandMoreOutlined, PrintOutlined } from '@material-ui/icons';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	Input,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { deliveryPrices } from '../../../config';
import { Routes } from '../../../routes';
import { HttpError, HttpSuccess } from '../../snackbars';
// import PaypalExpressBtn from 'react-paypal-express-checkout'
// confiscated
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
	dashAddDeliveryAction,
	dashGetInvoiceAction,
	dashGetUserInfoAction,
	dashPayInvoiceAction,
} from '../../../actions/DashAction';
import { useAppSelector } from '../../../store/store';
import customDateFormatter, { costFormatter } from '../../../utils/customDateFormatter';
import { addComma } from '../../../utils/stringConverter';
import Loader from '../../common/loader/Loader';
import '../Dashboard.scss';
import './Invoices.scss';

const mql = window.matchMedia(`(max-width: 1024px)`);

const code = 'vitumob-llc-e1470522d902';

const Invoice = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectProps = createSelector(
		[
			state => state.dash.invoiceSuccess,
			state => state.dash.userInfoFetched,
			state => state.dash.userInfo,
			state => state.dash.successMsg,
			state => state.dash.errorMsg,
			state => state.dash.invoice,
			state => state.dash.userAddress,
		],
		(invoiceSuccess, userInfoFetched, userInfo, successMsg, errorMsg, invoiceRdx, userAddressRdx) => {
			return {
				invoiceSuccess,
				userInfoFetched,
				userInfo,
				successMsg,
				errorMsg,
				invoiceRdx,
				userAddressRdx,
			};
		}
	);

	const { invoiceSuccess, userInfoFetched, userInfo, successMsg, errorMsg, invoiceRdx, userAddressRdx } =
		useAppSelector(selectProps);

	const [state, setState] = useState({
		invoice: {},
		deliveryRows: [],
		deliveryTotal: 0,
		deliveryAddressIndex: 0,
		openPayment: false,
		paymentNumber: '',
		deliveryOptionDialogOpen: false,
		paymentDialogOpen: false,
		paypalError: '',
		paypalSuccess: '',
		notificationsDialogOpen: false,
		errorNoAddress: false,
		confiscated: false,
		hazardousGoods: 0,
		userAddress: [],
		emptyPartial: false,
		totalDebitsKsh: 0,
		totalCreditsKsh: 0,
		totalPaymentsKsh: 0,
		totalDebits: 0,
		totalCredits: 0,
		totalPayments: 0,
		totalKgs: 0,
		totalCostKes: 0,
		totalCost: 0,
	});

	const [deliverySet, setDeliverySet] = useState(false);
	const [partialPayment, setPartialPayment] = useState(0);

	const { id } = useParams();

	useEffect(
		() => {
			dispatch(dashGetUserInfoAction());
			dispatch(dashGetInvoiceAction(id));
			if (localStorage.invoiceLink) {
				localStorage.removeItem('invoiceLink');
			}
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			setState({
				...state,
				invoice: invoiceRdx,
				userAddress: userAddressRdx,
				paymentNumber: userInfo.phoneNumber || '',
				hazardousGoods: parseFloat(invoiceRdx.dangerousGoodsFee),
				totalCostKes: parseFloat(invoiceRdx.totalCostKes),
				totalCost: invoiceRdx.totalCost,
			});

			setPartialPayment(invoiceRdx.totalCostKes);

			setDeliverySet(
				invoiceRdx.debits && invoiceRdx.debits.some(debit => debit.description.includes('Delivery'))
			);

			if (invoiceRdx.debits) {
				let totalKsh = invoiceRdx.debits
					.map(debit => Number(debit.amountKes))
					.reduce((a, b) => {
						return a + b;
					});
				let total = invoiceRdx.debits
					.map(debit => Number(debit.amount))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalDebitsKsh: totalKsh });
				setState({ ...state, totalDebits: total });
			}

			if (invoiceRdx.credits) {
				let totalKsh = invoiceRdx.credits
					.map(credit => Number(credit.amountKes))
					.reduce((a, b) => {
						return a + b;
					});
				let total = invoiceRdx.credits
					.map(credit => Number(credit.amount))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalCreditsKsh: totalKsh });
				setState({ ...state, totalCredits: total });
			}

			if (invoiceRdx.payments) {
				let totalKsh = invoiceRdx.payments
					.map(payment => Number(payment.amountPaidKes))
					.reduce((a, b) => {
						return a + b;
					});
				let total = invoiceRdx.payments
					.map(payment => Number(payment.amountPaid))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalPaymentsKsh: totalKsh });
				setState({ ...state, totalPayments: total });
			}
			if (invoiceRdx.consolidatedItems) {
				let totalKgs = invoiceRdx.consolidatedItems
					.map(item => Number(item.weight))
					.reduce((a, b) => {
						return a + b;
					});
				setState({ ...state, totalKgs });
			}
		},
		// eslint-disable-next-line
		[invoiceRdx, userInfo, userAddressRdx]
	);

	const onOpenPaymentDialog = () => {
		if (deliverySet) {
			const { userAddress } = state;
			if (userAddress.length === 0) {
				setState({ ...state, errorNoAddress: true });
				return;
			}
			setState({ ...state, paymentDialogOpen: true });
		} else {
			setState({ ...state, deliveryOptionDialogOpen: true, openPayment: true });
		}
	};

	const onPrintInvoice = () => {
		var content = document.getElementById('invoice_to_print');
		var iframe = document.getElementById('print_invoice_iframe').contentWindow;

		// const iframe = window.open('', '', '')
		iframe.document.write('<html><head><title>Invoice</title>');
		iframe.document.write('<base href="' + window.location.origin + window.location.pathname + '">');
		iframe.document.write(`<link rel="stylesheet" href="/invoices.css" />`);
		iframe.document.write(`<style type="text/css">
		#invoice_to_print{
			background: green;
		}
		</style>`);
		iframe.document.write('</head><body >');
		iframe.document.write(content.innerHTML);
		iframe.document.write('</body></html>');

		iframe.document.close();
		console.log(iframe.document);
		iframe.focus();
		iframe.print();
	};

	const onDeliveryOptionChange = async event => {
		const { userAddress } = state;
		if (userAddress.length === 0) {
			setState({ ...state, errorNoAddress: true, deliveryOptionDialogOpen: false });
			return;
		}
		const exchangeRates = invoiceRdx.exchangeRate;
		let exchangeRate = parseFloat(exchangeRates);

		const deliveryIn = Math.round(event.target.value) / exchangeRate;
		setDeliverySet(true);
		setState({
			...state,
			deliveryTotal: Math.round(event.target.value),
			// debit to the system
			deliveryRows: [
				{
					name: `Delivery: ${event.target.name} region`,
					quantity: '',
					price: Math.round(event.target.value),
				},
			],
		});
	};

	const onPayInvoice = () => {
		const { userAddress } = state;
		if (partialPayment === 0) {
			setState({ ...state, emptyPartial: true });
			return;
		} else {
			setState({ ...state, emptyPartial: false });
		}
		if (userAddress.length === 0) {
			setState({ ...state, errorNoAddress: true, paymentDialogOpen: false });
			return;
		}
		setState({ ...state, paymentDialogOpen: false });
		const mobile = state.paymentNumber;
		const address = userAddress[state.deliveryAddressIndex];
		dispatch(dashPayInvoiceAction(id, mobile, partialPayment, address));
	};

	const onAddDelivery = () => {
		const { deliveryRows } = state;
		if (deliveryRows.length === 0) {
			return;
		}
		const payload = {};
		payload.description = deliveryRows[0].name;
		payload.amountKes = deliveryRows[0].price.toString();
		payload.invoiceNumber = id;
		dispatch(dashAddDeliveryAction(payload));
		setState({ ...state, deliveryOptionDialogOpen: false });
	};

	const Regions = deliveryPrices.map(price => {
		let weight = state.totalKgs;

		if (weight >= price.weightFrom && weight <= price.weightTo) {
			return (
				<Paper className='clean_paper' key={price.region}>
					<FormControlLabel
						value={`${price.price}`}
						onChange={onDeliveryOptionChange}
						name={`${price.region}`}
						label={`${price.region}`}
						control={<Radio color='primary' />}
					/>
					&nbsp;&nbsp;&nbsp;Ksh. {price.price}
					<Divider />
				</Paper>
			);
		}
		return <div key={Math.random()}></div>;
	});

	return (
		<>
			{invoiceSuccess ? (
				<div id='single-invoice'>
					<Paper
						square={true}
						style={{
							background: 'rgba(0,0,0,0)',
							boxShadow: 'none',
							paddingLeft: mql.matches ? '0' : '240px',
							paddingTop: '10vh',
						}}
					>
						{/* Invoice printing */}
						<iframe
							id='print_invoice_iframe'
							title='Print invoice'
							style={{ height: '0px', width: '0px', position: 'absolute' }}
						></iframe>
						{/* The Invoice */}
						<Grid item xs={12}>
							<div
								className={invoiceRdx.paid ? 'invoice_card_paid' : 'invoice_card'}
								id='invoice_to_print'
							>
								<Grid container spacing={0}>
									<Grid item xs={12}>
										<Paper className='invoice_head px-8 pt-12'>
											<Grid container spacing={0}>
												<Grid
													item
													xs={12}
													style={{
														background: '#F07829',
														color: 'white',
														fontStyle: 'bold',
														borderRadius: '8px',
													}}
													className='py-3 pl-5'
												>
													<span style={{ fontSize: '2.2em' }}>Invoice</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3}>
													<Paper
														className='dashboard_clear_drawer_inv'
														style={{ cursor: 'default' }}
													></Paper>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3} style={{ marginTop: '35px' }}>
													<span>
														Vitumob Company Limited,
														<br />
														Storage Central Self-Storage 1st Floor,
														<br />
														Mombasa Road,
														<br />
														Nairobi.
													</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 5} style={{ margin: '35px' }}>
													<table style={{ width: '100%' }}>
														<tr className='issued'>
															<th>Date Issued</th>
															<th>Invoice #</th>
														</tr>
														<tr>
															<td className='text-center'>
																{customDateFormatter(invoiceRdx.invoiceUpdatedOn)}
															</td>
															<td className='text-center'>
																{invoiceRdx.id.toUpperCase()}
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 4}
													style={{ margin: '15px', marginTop: '20px' }}
												>
													<table style={{ width: '100%' }}>
														<tr className='billed'>
															<th>Bill To:</th>
														</tr>

														<tr>
															<td className=''>
																{`${userInfo.client && userInfo.client.firstName} ${userInfo.client && userInfo.client.lastName}`}
																<br />
																{userAddressRdx &&
																	userAddressRdx.length > 0 &&
																	userAddressRdx[state.deliveryAddressIndex]
																		.addressTown}
																,
																<br />
																{userInfoFetched && userInfo.client.phoneNumber}
																<br />
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 4}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '90%' }}>
														<tr className='billed'>
															<th>Origin:</th>
														</tr>
														<tr>
															<td className=''>{invoiceRdx.country}</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 3}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													{invoiceRdx.paid ||
														(invoiceRdx.paymentStatus === 'FULLY_PAID' && (
															<>
																<img src='/paid.png' width={100} alt='' />
															</>
														))}
												</Grid>
												<Grid item xs={12} style={{ margin: '15px' }}>
													{/* Box items */}
													{'consolidatedItems' in invoiceRdx &&
														invoiceRdx.consolidatedItems.length &&
														invoiceRdx.consolidatedItems.map((box, index) => {
															return (
																<table
																	key={Math.random()}
																	className='long-table'
																	style={{ width: '100%' }}
																>
																	<tr>
																		<th>
																			Box:&nbsp;<b>{index + 1}</b>
																			,&nbsp;&nbsp;&nbsp; Box weight:&nbsp;
																			<b>{box.weight} Kg</b>,&nbsp;&nbsp;&nbsp;
																			Total box items:&nbsp;
																			<b>{box.packages && box.packages.length}</b>
																		</th>
																	</tr>
																	{box.packages &&
																		box.packages.map((item, index) => {
																			return (
																				<tr
																					key={index}
																					className='long-table-height'
																				>
																					<td>
																						Tracking Number: &nbsp;
																						{item.trackingNumber}
																					</td>
																				</tr>
																			);
																		})}
																</table>
															);
														})}
													<table style={{ width: '100%', marginTop: '10px' }}>
														<tr className='long-table'>
															<th>Cost Category</th>
															<th>Debit</th>
															<th>Credit</th>
														</tr>
														{/* {state.invoice._specialCharges.map((item) => {
													return (<td key={item.id}>
														<td>{item.description}</td>
														<td>{invoiceRdx.foreignCurrency}. {item.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
													</td>)}
												)} */}
														<tr className='long-table-pad'>
															<td style={{ color: 'blue' }}>
																Total Weight - {state.totalKgs}&nbsp;Kg(s)
															</td>
															<td>
																Ksh. {addComma(invoiceRdx.weightCostKes)} -{' '}
																{invoiceRdx.foreignCurrency}.{' '}
																{addComma(invoiceRdx.weightCost)}
															</td>
															<td>--</td>
														</tr>
														<tr className='long-table-pad'>
															<td>Hazardous Goods Fee</td>
															<td>
																Ksh. {addComma(invoiceRdx.dangerousGoodsFeeKes)} -{' '}
																{invoiceRdx.foreignCurrency}.{' '}
																{addComma(invoiceRdx.dangerousGoodsFee)}
															</td>
															<td>--</td>
														</tr>
														{invoiceRdx.debits &&
															invoiceRdx.debits.map(debit => {
																return (
																	<tr className='long-table-pad'>
																		<td>{debit.description}</td>
																		<td>
																			Ksh. {addComma(debit.amountKes)} -{' '}
																			{invoiceRdx.foreignCurrency}. {debit.amount}
																		</td>
																		<td>--</td>
																	</tr>
																);
															})}
														{invoiceRdx.credits &&
															invoiceRdx.credits.map(credit => {
																return (
																	<tr className='long-table-pad'>
																		<td>{credit.description}</td>
																		<td>--</td>
																		<td>
																			Ksh. {addComma(credit.amountKes)} -{' '}
																			{invoiceRdx.foreignCurrency}.{' '}
																			{credit.amount}
																		</td>
																	</tr>
																);
															})}
														{invoiceRdx.payments &&
															invoiceRdx.payments.map(payment => {
																return (
																	<tr className='long-table-pad'>
																		<td>Payment: {payment.paymentType}</td>
																		<td>--</td>
																		<td>
																			Ksh. {addComma(payment.amountPaidKes)} -
																			{invoiceRdx.foreignCurrency}.{' '}
																			{addComma(payment.amountPaid)}
																		</td>
																	</tr>
																);
															})}
														{invoiceRdx.storageCosts &&
															invoiceRdx.storageCosts.map(cost => {
																return (
																	<tr className='long-table-pad'>
																		<td>
																			Storage Cost:{' '}
																			{cost.addedOn &&
																				costFormatter(cost.addedOn)}
																		</td>
																		<td>
																			Ksh. {addComma(cost.amountKes)} -{' '}
																			{invoiceRdx.foreignCurrency}.{' '}
																			{addComma(cost.amount)}
																		</td>
																		<td>--</td>
																	</tr>
																);
															})}
													</table>
												</Grid>
												{/* <Grid item xs={mql.matches ? 12 : 7} style={{ margin: '15px', marginTop: '30px' }}>
												<div>
													<p>US Pricing: <br />Up to 2 kg - $25. <br />2 kg or more - $15 per kg.</p>
												</div>
											</Grid> */}
												<Grid
													item
													xs={mql.matches ? 12 : 7}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<div>
														<h4 style={{ color: '#F07829' }}>Terms & Conditions</h4>
														<p>
															For our full terms and conditions -{' '}
															<a
																href='/dashboard/terms_of_use'
																target='_blank'
																rel='noopener noreferrer'
															>
																here
															</a>
														</p>
													</div>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 4}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '100%' }} className='inverted'>
														<tr>
															<th>Invoice Total ({invoiceRdx.foreignCurrency})</th>
															<td>
																<span>{addComma(invoiceRdx.totalCost)}</span>
															</td>
														</tr>
														<tr>
															<th>Invoice Total (KES)</th>
															<td>
																<span>{addComma(invoiceRdx.totalCostKes)}</span>
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 12}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<div>
														<p>
															Please initiate your payment through this invoice or if your
															like to pay via cash transfer please use the information
															below:
														</p>
														<p>
															<i>
																Account name: Vitumob Company Limited,
																<br />
																Bank: Equity Bank Ltd,
																<br />
																Branch: Fourways Towers
																<br />
																Kes Acc no. 0020260517036,
																<br />
																USD Acc no. 1450274915919,
															</i>
														</p>
														<p>
															<i>Call assistance: 0717 363433</i>
														</p>
													</div>
												</Grid>
											</Grid>
											{state.errorNoAddress && (
												<Grid item xs={12} className='pl-5'>
													<Paper
														style={{
															background: 'rgba(255,69,0,0.2)',
															color: 'red',
														}}
														className='py-1 px-3'
													>
														You need a delivery address in order to pay for an invoice.
														Please add at least one address&nbsp;
														<Button
															onClick={() => navigate(Routes.EditProfile)}
															color='primary'
														>
															here
														</Button>
													</Paper>
												</Grid>
											)}
											<Grid item xs={12}>
												{deliverySet ? (
													<div></div>
												) : (
													<div className='pt-5 pl-5'>
														<Button
															onClick={() => {
																setState({ ...state, deliveryOptionDialogOpen: true });
															}}
															style={{
																color: 'darkblue',
																background: 'rgba(0,0,255,0.4)',
															}}
														>
															Add Delivery
														</Button>
													</div>
												)}
											</Grid>
											<Grid item xs={12}>
												<Paper className='invoice_options_paper'>
													{(invoiceRdx.paid || invoiceRdx.paymentStatus) === 'FULLY_PAID' ? (
														<div></div>
													) : (
														<Button
															onClick={onOpenPaymentDialog}
															className='invoice_options_paper_btn'
															style={{ background: '#f07829' }}
														>
															Proceed to Payment
														</Button>
													)}
													<Button
														className='invoice_options_paper_btn'
														onClick={onPrintInvoice}
														style={{ background: '#232C39' }}
													>
														<PrintOutlined />
														&nbsp;Print
													</Button>
												</Paper>
											</Grid>
										</Paper>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Paper>
					{/* Delivery dialog */}
					<Dialog
						open={state.deliveryOptionDialogOpen}
						onClose={() => setState({ ...state, deliveryOptionDialogOpen: false })}
					>
						<DialogTitle>Region</DialogTitle>
						<DialogContent>
							<RadioGroup>{Regions}</RadioGroup>
							<FormControlLabel
								value={`0`}
								onChange={onDeliveryOptionChange}
								name={`Pick up `}
								label={`Pick up`}
								control={<Radio color='primary' />}
							/>
						</DialogContent>
						<DialogActions>
							<Button
								color='primary'
								onClick={() => setState({ ...state, deliveryOptionDialogOpen: false })}
							>
								Close
							</Button>
							<Button
								color='primary'
								onClick={() => {
									onAddDelivery();
									if (state.openPayment) {
										setState({ ...state, paymentDialogOpen: true });
									}
								}}
							>
								Add
							</Button>
						</DialogActions>
					</Dialog>
					{/* Payment Dialog */}
					<Dialog
						open={state.paymentDialogOpen}
						fullWidth
						onClose={() => setState({ ...state, paymentDialogOpen: false })}
					>
						<DialogTitle>Payment</DialogTitle>
						<DialogContent>
							{/* M-PESA */}
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreOutlined />}>
									<Typography className='invoice_pay_mpesa_logo'>Pay with M-PESA</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>1. Confirm or enter your payment phone number below</Typography>
								</AccordionDetails>
								<AccordionDetails>
									<Input
										value={state.paymentNumber}
										name='paymentNumber'
										onChange={event => setState({ ...state, paymentNumber: event.target.value })}
										placeholder='Phone number'
										style={{ margin: '3%', width: '90%' }}
									/>
								</AccordionDetails>
								<AccordionDetails>
									<Typography>2. Enter the price you wish to pay</Typography>
								</AccordionDetails>
								<AccordionDetails>
									<Input
										value={partialPayment}
										name='partialPayment'
										onChange={event => setPartialPayment(event.target.value)}
										placeholder='Enter amount'
										style={{ margin: '3%', width: '90%' }}
									/>
								</AccordionDetails>
								<AccordionDetails>
									<Typography>4. Make sure your phone is unlocked</Typography>
								</AccordionDetails>
								<AccordionDetails>
									<Typography>5. Click the pay button below</Typography>
								</AccordionDetails>
								<AccordionDetails>
									<Typography>
										6. You will be prompted to enter your M-Pesa pin to pay KES.{' '}
										{addComma(partialPayment)}
									</Typography>
								</AccordionDetails>
								<AccordionDetails>
									<Paper className='clean_paper' style={{ textAlign: 'right' }}>
										<Button onClick={onPayInvoice} style={{ background: 'green', color: 'white' }}>
											Pay
										</Button>
									</Paper>
								</AccordionDetails>
							</Accordion>
							{/* Fatt Merchant */}
							<Accordion>
								<AccordionSummary expandIcon={<ExpandMoreOutlined />}>
									<Typography className='invoice_pay_fatt_merchant_logo'>
										Pay with Credit Card
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Typography>Please click the link below</Typography>
								</AccordionDetails>
								<AccordionDetails>
									<Typography>
										<a
											style={{ textDecoration: 'none', color: '#00416A' }}
											href={`https://omni.fattmerchant.com/#/pay/${code}?memo=${invoiceRdx.id}&total=${invoiceRdx.totalCost}&r=${window.location.href}`}
										>
											<Button style={{ background: '#EF6B20' }}> Pay </Button>
										</a>
									</Typography>
								</AccordionDetails>
							</Accordion>
						</DialogContent>
					</Dialog>
					{state.emptyPartial ? <HttpError errorMessage={'The cost cannot be empty'} /> : null}
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
				</div>
			) : (
				<div className='absolute top-1/2 left-1/2'>
					<Loader />
				</div>
			)}
		</>
	);
};

export default Invoice;
