import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	InputLabel,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	officerGetInvoiceAction,
	officerMarksDeliveredAction,
	officerPayInvoiceAction,
	officerSendInvoiceAction,
} from '../../../actions/OfficerActions';
import { useAppSelector } from '../../../store/store';
import customDateFormatter, { costFormatter } from '../../../utils/customDateFormatter';
import { addComma } from '../../../utils/stringConverter';
import '../../admin/Admin.scss';
import Loader from '../../common/loader/Loader';
import '../../dashboard/Dashboard.scss';
import { HttpError, HttpSuccess } from '../../snackbars';
import { useStyles } from './drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const OfficerInvoice = props => {
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const { auth, officer } = useSelector(state => ({
		auth: state.auth,
		officer: state.officer,
	}));

	const selectProps = createSelector(
		[
			state => state.officer.invoiceFetched,
			state => state.officer.client,
			state => state.officer.successMsg,
			state => state.officer.errorMsg,
			state => state.officer.invoice,
			state => state.officer.userAddress,
		],
		(invoiceFetched, client, successMsg, errorMsg, invoiceRdx, userAddressRdx) => {
			return {
				invoiceFetched,
				client,
				successMsg,
				errorMsg,
				invoiceRdx,
				userAddressRdx,
			};
		}
	);

	const { invoiceFetched, client, successMsg, errorMsg, invoiceRdx, userAddressRdx } = useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		officerInfo: {},
		invoice: {},
		paymentType: '',
		referenceNumber: '',
		formErrors: '',
		payInvoiceDialogOpen: false,
		hazardousGoods: 0,
		totalDemmurage: 0,
		deliverlyAmount: 0,
		client: {},
		phoneNumber: '',
		amountPaidKes: '',
		userAddress: [],
		totalDebitsKsh: 0,
		totalCreditsKsh: 0,
		totalPaymentsKsh: 0,
		totalDebits: 0,
		totalCredits: 0,
		totalPayments: 0,
		totalKgs: 0,
		totalCostKes: 0,
		totalCost: 0,
	});

	const { id } = useParams();

	useEffect(
		() => {
			dispatch(officerGetInvoiceAction(id));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			// setState({
			// 	...state,
			// 	officerInfo,
			// 	invoice,
			// 	hazardousGoods: parseFloat(invoiceRdx.dangerousGoodsFeeKes),
			// 	totalDemmurage: parseFloat(invoiceRdx.totalDemurrageFee),
			// 	deliverlyAmount: parseFloat(invoiceRdx.deliverlyAmount),
			// 	client,
			// 	userAddress,
			// });

			setState({
				...state,
				invoice: invoiceRdx,
				userAddress: userAddressRdx,
				hazardousGoods: parseFloat(invoiceRdx.dangerousGoodsFee),
				totalCostKes: parseFloat(invoiceRdx.totalCostKes),
				totalDemmurage: parseFloat(invoiceRdx.totalDemurrageFee),
				deliverlyAmount: parseFloat(invoiceRdx.deliverlyAmount),
				totalCost: invoiceRdx.totalCost,
				client,
			});

			if (invoiceRdx.debits) {
				let totalKsh = invoiceRdx.debits
					.map(debit => Number(debit.amountKes))
					.reduce((a, b) => {
						return a + b;
					});
				let total = invoiceRdx.debits
					.map(debit => Number(debit.amount))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalDebitsKsh: totalKsh });
				setState({ ...state, totalDebits: total });
			}

			if (invoiceRdx.credits) {
				let totalKsh = invoiceRdx.credits
					.map(credit => Number(credit.amountKes))
					.reduce((a, b) => {
						return a + b;
					});
				let total = invoiceRdx.credits
					.map(credit => Number(credit.amount))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalCreditsKsh: totalKsh });
				setState({ ...state, totalCredits: total });
			}

			if (invoiceRdx.payments) {
				let totalKsh = invoiceRdx.payments
					.map(payment => Number(payment.amountPaidKes))
					.reduce((a, b) => {
						return a + b;
					});
				let total = invoiceRdx.payments
					.map(payment => Number(payment.amountPaid))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalPaymentsKsh: totalKsh });
				setState({ ...state, totalPayments: total });
			}

			if (invoiceRdx.consolidatedItems) {
				let totalKgs = invoiceRdx.consolidatedItems
					.map(item => Number(item.weight))
					.reduce((a, b) => {
						return a + b;
					});
				setState({ ...state, totalKgs });
			}
		},
		// eslint-disable-next-line
		[invoiceRdx, userAddressRdx]
	);

	const onInputChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onPayInvoice = event => {
		event.preventDefault();

		const { referenceNumber, paymentType, phoneNumber, amountPaidKes } = state;
		if (!referenceNumber || !paymentType || !phoneNumber || !amountPaidKes) {
			setState({ ...state, formErrors: 'Payment type and all fields are required' });
			return;
		}
		dispatch(
			officerPayInvoiceAction(
				referenceNumber,
				phoneNumber,
				amountPaidKes,
				id,
				paymentType,
				officerGetInvoiceAction
			)
		);
		setState({ ...state, payInvoiceDialogOpen: false, formErrors: '' });
	};

	return (
		<>
			{invoiceFetched ? (
				<div id='single-invoice'>
					<Paper
						square={true}
						style={{
							background: 'rgba(0,0,0,0)',
							boxShadow: 'none',
							paddingLeft: mql.matches ? '0' : '240px',
							paddingTop: '10vh',
						}}
					>
						<Grid item xs={12}>
							<div className='invoice_card'>
								<Grid container spacing={0}>
									<Grid>
										<Paper className='invoice_head px-8 pt-12'>
											<Grid container spacing={0}>
												<Grid
													item
													xs={12}
													style={{
														background: '#F07829',
														color: 'white',
														fontStyle: 'bold',
														borderRadius: '8px',
													}}
													className='py-3 pl-5'
												>
													<span style={{ fontSize: '2.2em' }}>Invoice</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3}>
													<span>
														<Paper
															className='dashboard_clear_drawer_inv'
															style={{ cursor: 'default' }}
														></Paper>
													</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3} style={{ marginTop: '15px' }}>
													<span>
														Vitumob Company Limited,
														<br />
														Storage Central Self-Storage 1st Floor,
														<br />
														Mombasa Road,
														<br />
														Nairobi.
													</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 5} style={{ margin: '15px' }}>
													<table style={{ width: '100%' }}>
														<tr className='issued'>
															<th>Date Issued</th>
															<th>Invoice #</th>
														</tr>
														<tr>
															<td className='text-center'>
																{customDateFormatter(invoiceRdx.invoiceUpdatedOn)}
															</td>
															<td className='text-center'>
																{invoiceRdx.id.toUpperCase()}={' '}
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 5}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '90%' }}>
														<tr className='billed'>
															<th>Bill To:</th>
														</tr>
														<tr>
															<td className=''>
																{`${client.client && client.client.firstName} ${client.client && client.client.lastName}`}
																<br />
																{userAddressRdx.length > 0 &&
																	userAddressRdx[0].addressTown}
																,
																<br />
																{client.client && client.client.phoneNumber}
																<br />
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 5}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '90%' }}>
														<tr className='billed'>
															<th>Origin:</th>
														</tr>
														<tr>
															<td className=''>{invoiceRdx.country}</td>
														</tr>
													</table>
												</Grid>
												<Grid item xs={12} style={{ margin: '15px' }}>
													{/* Box items */}
													{'consolidatedItems' in invoiceRdx &&
													invoiceRdx.consolidatedItems.length ? (
														invoiceRdx.consolidatedItems.map((box, index) => {
															return (
																<table
																	key={Math.random()}
																	className='long-table'
																	style={{ width: '100%' }}
																>
																	<tr>
																		<th>
																			Box:&nbsp;<b>{index + 1}</b>
																			,&nbsp;&nbsp;&nbsp; Box weight:&nbsp;
																			<b>{box.weight} Kg</b>,&nbsp;&nbsp;&nbsp;
																			Total box items:&nbsp;
																			<b>{box.packages && box.packages.length}</b>
																		</th>
																	</tr>
																	{box.packages &&
																		box.packages.map((item, index) => {
																			return (
																				<tr
																					key={index}
																					className='long-table-height'
																				>
																					<td>
																						Tracking Number: &nbsp;
																						{item.trackingNumber}
																					</td>
																				</tr>
																			);
																		})}
																</table>
															);
														})
													) : (
														<div></div>
													)}
													<table style={{ width: '100%', marginTop: '10px' }}>
														<tr className='long-table'>
															<th>Cost Category</th>
															<th>Debit</th>
															<th>Credit</th>
														</tr>
														{/* {state.invoiceRdx._specialCharges.map((item) => {
																return (<td key={item.id}>
																	<td>{item.description}</td>
																	<td>{invoiceRdx.foreignCurrency}. {item.amount}</td>
																</td>)}
															)} */}
														<tr className='long-table-pad'>
															<td style={{ color: 'blue' }}>
																Total Weight - {state.totalKgs}&nbsp;Kg(s)
															</td>
															<td>
																Ksh. {addComma(invoiceRdx.weightCostKes)} -{' '}
																{invoiceRdx.foreignCurrency}.{' '}
																{addComma(invoiceRdx.weightCost)}
															</td>
															<td>--</td>
														</tr>
														<tr className='long-table-pad'>
															<td>Hazardous Goods Fee</td>
															<td>
																Ksh. {addComma(invoiceRdx.dangerousGoodsFeeKes)} -{' '}
																{invoiceRdx.foreignCurrency}.{' '}
																{addComma(invoiceRdx.dangerousGoodsFee)}
															</td>
															<td>--</td>
														</tr>
														{/* {('dangerousGoodsFeeKes' in invoiceRdx ||
															'dangerousGoodsFee' in invoiceRdx) && (
															<tr>
																<td>Hazardous Goods Fee</td>
																<td>
																	Ksh. {invoiceRdx.dangerousGoodsFeeKes} - {invoiceRdx.foreignCurrency}.{' '}
																	{invoiceRdx.dangerousGoodsFee}
																</td>
																<td>--</td>
															</tr>
														)} */}
														{invoiceRdx.debits &&
															invoiceRdx.debits.map(debit => {
																return (
																	<tr>
																		<td>{debit.description}</td>
																		<td>
																			Ksh. {addComma(debit.amountKes)} -{' '}
																			{debit.foreignCurrency}. {debit.amount}
																		</td>
																		<td>--</td>
																	</tr>
																);
															})}
														{invoiceRdx.credits &&
															invoiceRdx.credits.map(credit => {
																return (
																	<tr>
																		<td>{credit.description}</td>
																		<td>--</td>
																		<td>
																			Ksh. {addComma(credit.amountKes)} -{' '}
																			{credit.foreignCurrency}. {credit.amount}
																		</td>
																	</tr>
																);
															})}
														{invoiceRdx.payments &&
															invoiceRdx.payments.map(payment => {
																return (
																	<tr>
																		<td>Payment: {payment.paymentType}</td>
																		<td>--</td>
																		<td>
																			Ksh. {addComma(payment.amountPaidKes)} -
																			{invoiceRdx.foreignCurrency}.{' '}
																			{payment.amountPaid}
																		</td>
																	</tr>
																);
															})}
														{invoiceRdx.storageCosts &&
															invoiceRdx.storageCosts.map(cost => {
																return (
																	<tr>
																		<td>
																			Storage Cost: {costFormatter(cost.addedOn)}
																		</td>
																		<td>
																			Ksh. {addComma(cost.amountKes)} -{' '}
																			{invoiceRdx.foreignCurrency}. {cost.amount}
																		</td>
																		<td>--</td>
																	</tr>
																);
															})}
														{/* {state.deliverlyAmount > 0 ? (
																<tr>
																<td style={{color: 'blue'}}>Hazardous Goods Fee</td>
																<td>
																	{invoiceRdx.foreignCurrency}. {state.deliverlyAmount.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") } -  Ksh. {(state.deliverlyAmount * exchangeRate).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
																</td>
															</tr>
															) : (
																null
															)} */}
													</table>
												</Grid>
												{/* <Grid item xs={mql.matches ? 12 : 7} style={{ margin: '15px', marginTop: '30px' }}>
														<div>
															<p>US Pricing: <br />Up to 2 kg - $25. <br />2 kg or more - $15 per kg.</p>
														</div>
													</Grid> */}
												<Grid
													item
													xs={mql.matches ? 12 : 7}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<div>
														<h4 style={{ color: '#F07829' }}>Terms & Conditions</h4>
														<p>
															For our full terms and conditions -{' '}
															<a
																href='/dashboard/terms_of_use'
																target='_blank'
																rel='noopener noreferrer'
															>
																here
															</a>
														</p>
													</div>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 4}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '90%' }} className='inverted'>
														<tr>
															<th>Invoice Total ({invoiceRdx.foreignCurrency})</th>
															<td>
																<span>{addComma(invoiceRdx.totalCost)}</span>
															</td>
														</tr>
														<tr>
															<th>Invoice Total (KES)</th>
															<td>
																<span>{addComma(invoiceRdx.totalCostKes)}</span>
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 12}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<div>
														<p>
															Please initiate your payment through this invoice or if your
															like to pay via cash transfer please use the information
															below:
														</p>
														<p>
															<i>
																Account name: Vitumob Company Limited,
																<br />
																Bank: Equity Bank Ltd,
																<br />
																Branch: Fourways Towers
																<br />
																Kes Acc no. 0020260517036,
																<br />
																USD Acc no. 1450274915919,
															</i>
														</p>
														<p>
															<i>Call assistance: 0717 363433</i>
														</p>
													</div>
												</Grid>
												<Grid item xs={12}>
													<Paper className='clean_paper' style={{ textAlign: 'right' }}>
														{invoiceRdx.paymentStatus === 'FULLY_PAID' ? (
															invoiceRdx.isDelivered ? (
																<Typography style={{ color: 'rgb(0,255,0)' }}>
																	PAID & DELIVERED
																</Typography>
															) : (
																<Button
																	onClick={() => {
																		let newArray = [];
																		newArray.push(invoiceRdx.id);
																		const payload = {};
																		payload.invoiceNumbers = newArray;
																		dispatch(
																			officerMarksDeliveredAction(payload, 2)
																		);
																	}}
																	color='secondary'
																>
																	Mark Delivered
																</Button>
															)
														) : (
															<div>
																<Button
																	onClick={() =>
																		setState({
																			...state,
																			payInvoiceDialogOpen: true,
																		})
																	}
																	color='secondary'
																>
																	Pay invoice
																</Button>
															</div>
														)}
														{!invoiceRdx.clientView && (
															<div>
																<Button
																	onClick={() =>
																		dispatch(
																			officerSendInvoiceAction(invoiceRdx.id)
																		)
																	}
																	style={{ color: '#58A147' }}
																>
																	Send Invoice to Client
																</Button>
															</div>
														)}
													</Paper>
												</Grid>
												{/* <Grid item xs={12}>
																		<Paper className="invoice_summary_paper">
																			<Typography>Weight:&nbsp;<span style={{color: 'blue'}}>
																				{(parseFloat(invoiceRdx.weight) / 2.20462).toFixed(2)} Kgs</span>
																			</Typography>
													<Typography>Demurrage fee:&nbsp;<span style={{color: 'blue'}}>
														KES. {parseFloat(invoiceRdx.totalDemurrageFee).toFixed(2)}</span>
																			</Typography>
																			<Typography>Shipping cost:&nbsp;<span style={{color: 'blue'}}>
																				{invoiceRdx.foreignCurrency}. {parseFloat(invoiceRdx.shippingAmount).toFixed(2)}</span>
																			</Typography>
													<Typography>Hazardous good fee:&nbsp;
																				<span style={{color: 'blue'}}>{invoiceRdx.foreignCurrency}. {invoiceRdx.totalHazardousGoodFee}</span>
																			</Typography>
																			<Typography>Delivery cost:&nbsp;
																				<span style={{color: 'blue'}}>KSH. {invoiceRdx.deliveryAmount}</span>
																			</Typography>
																		</Paper>
																	</Grid> */}
											</Grid>
										</Paper>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Paper>
					{/* Pay invoice dialog
					 **********************
					 **********************
					 ********************/}
					{/* state.payInvoiceDialogOpen */}
					<Dialog
						open={state.payInvoiceDialogOpen}
						onClose={() => setState({ ...state, payInvoiceDialogOpen: false })}
					>
						<DialogTitle>Pay invoice</DialogTitle>
						<form onSubmit={onPayInvoice}>
							<DialogContent>
								<InputLabel>Payment type</InputLabel>
								<FormControl>
									<RadioGroup
										name='paymentType'
										value={state.paymentType}
										onChange={onInputChange}
										row
									>
										<FormControlLabel
											value='MPESA'
											control={<Radio color='primary' />}
											label='MPesa'
										/>
										<FormControlLabel
											value='Check'
											control={<Radio color='primary' />}
											label='Check'
										/>
										<FormControlLabel
											value='BankTransfer'
											control={<Radio color='primary' />}
											label='Electronic funds transfer'
										/>
									</RadioGroup>
								</FormControl>
							</DialogContent>
							<DialogContent>
								<TextField
									name='referenceNumber'
									value={state.referenceNumber}
									onChange={onInputChange}
									variant='outlined'
									label='Payment reference number'
									required
									fullWidth
								/>
							</DialogContent>
							<DialogContent>
								<TextField
									name='phoneNumber'
									value={state.phoneNumber}
									onChange={onInputChange}
									variant='outlined'
									label='Phone number'
									required
									fullWidth
								/>
							</DialogContent>
							<DialogContent>
								<TextField
									name='amountPaidKes'
									value={state.amountPaidKes}
									onChange={onInputChange}
									variant='outlined'
									label='Amount paid in KES'
									required
									fullWidth
								/>
							</DialogContent>
							<DialogContent style={{ color: 'orangered' }}>{state.formErrors}</DialogContent>
							<DialogContent style={{ textAlign: 'right' }}>
								<Button type='submit' color='secondary'>
									Pay
								</Button>
							</DialogContent>
						</form>
					</Dialog>
					{errorMsg ? <HttpError errorMessage={errorMsg} /> : null}
					{successMsg ? <HttpSuccess successMessage={successMsg} /> : null}
				</div>
			) : (
				<div className='absolute top-1/2 left-1/2'>
					<Loader />
				</div>
			)}
		</>
	);
};

export default OfficerInvoice;
