import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Grid,
	Input,
	InputLabel,
	Paper,
	Radio,
	RadioGroup,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { createSelector } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
	adminAddCreditAction,
	adminAddDebitAction,
	adminGetInvoiceAction,
	adminPayInvoiceAction,
} from '../../actions/AdminActions';
import { useAppSelector } from '../../store/store';
import customDateFormatter, { costFormatter } from '../../utils/customDateFormatter';
import { addComma } from '../../utils/stringConverter';
import Loader from '../common/loader/Loader';
import '../dashboard/Dashboard.scss';
import { HttpError, HttpSuccess } from '../snackbars';
import './Admin.scss';
import { useStyles } from './drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminInvoice = props => {
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.admin.adminHttpErrors,
			state => state.admin.adminHttpSuccess,
			state => state.admin.invoiceEdited,
			state => state.admin.invoice,
			state => state.admin.invoiceFetched,
			state => state.admin.client,
			state => state.admin.userAddress,
		],
		(adminHttpErrors, adminHttpSuccess, invoiceEdited, invoiceRdx, invoiceFetched, client, userAddressRdx) => {
			return {
				adminHttpErrors,
				adminHttpSuccess,
				invoiceEdited,
				invoiceRdx,
				invoiceFetched,
				client,
				userAddressRdx,
			};
		}
	);

	const { adminHttpErrors, adminHttpSuccess, invoiceEdited, invoiceRdx, invoiceFetched, client, userAddressRdx } =
		useAppSelector(selectProps);

	const [state, setState] = useState({
		invoice: {},
		drawerOpen: false,
		profileAnchorEl: null,
		changeCostDialogOpen: false,
		payInvoiceDialogOpen: false,
		paymentType: '',
		referenceNumber: '',
		extraCosts: [],
		newShippingAmount: 0,
		description: '',
		amount: '',
		formErrors: '',
		addErrors: '',
		client: {},
		hazardousGoods: 0,
		totalDemmurage: 0,
		deliverlyAmount: 0,
		userAddress: [],
		totalDebitsKsh: 0,
		totalCreditsKsh: 0,
		totalPaymentsKsh: 0,
		totalDebitsUsd: 0,
		totalCreditsUsd: 0,
		totalPaymentsUsd: 0,
		phoneNumber: '',
		amountPaidKes: '',
	});

	const { id } = useParams();

	useEffect(
		() => {
			dispatch(adminGetInvoiceAction(id));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (invoiceEdited) {
				dispatch(adminGetInvoiceAction(id));
			}
			setState({
				...state,
				invoice: invoiceRdx,
				userAddress: userAddressRdx,
				newShippingAmount: invoiceRdx.shippingAmount,
				client,
				hazardousGoods: parseFloat(invoiceRdx.totalHazardousGoodFee),
				totalDemmurage: parseFloat(invoiceRdx.totalDemurrageFee),
				deliverlyAmount: parseFloat(invoiceRdx.deliverlyAmount),
			});

			if (invoiceRdx.debits) {
				let totalKsh = invoiceRdx.debits
					.map(debit => Number(debit.amountKes))
					.reduce((a, b) => {
						return a + b;
					});
				let totalUsd = invoiceRdx.debits
					.map(debit => Number(debit.amountUsd))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalDebitsKsh: totalKsh });
				setState({ ...state, totalDebitsUsd: totalUsd });
			}

			if (invoiceRdx.credits) {
				let totalKsh = invoiceRdx.credits
					.map(credit => Number(credit.amountKes))
					.reduce((a, b) => {
						return a + b;
					});
				let totalUsd = invoiceRdx.credits
					.map(credit => Number(credit.amountUsd))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalCreditsKsh: totalKsh });
				setState({ ...state, totalCreditsUsd: totalUsd });
			}

			if (invoiceRdx.payments) {
				let totalKsh = invoiceRdx.payments
					.map(payment => Number(payment.amountPaidKes))
					.reduce((a, b) => {
						return a + b;
					});
				let totalUsd = invoiceRdx.payments
					.map(payment => Number(payment.amountPaidUsd))
					.reduce((a, b) => {
						return a + b;
					});

				setState({ ...state, totalPaymentsKsh: totalKsh });
				setState({ ...state, totalPaymentsUsd: totalUsd });
			}
		},
		// eslint-disable-next-line
		[invoiceRdx, invoiceEdited, client, userAddressRdx]
	);

	const onInputChange = event => {
		setState({ ...state, [event.target.name]: event.target.value });
	};

	const onExtraCosts = action => {
		const { amount, description } = state;
		if (!amount || !description) {
			return setState({ ...state, addErrors: 'Amount or description cannot be empty' });
		}
		setState({ ...state, changeCostDialogOpen: false, addErrors: '' });
		switch (action) {
			case 'reduce':
				dispatch(adminAddCreditAction(description, amount, id, adminGetInvoiceAction));
				setState({ ...state, amount: '', description: '' });
				return;
			case 'add':
				dispatch(adminAddDebitAction(description, amount, id, adminGetInvoiceAction));
				setState({ ...state, amount: '', description: '' });
				return;
			default:
				return;
		}
	};

	const onPayInvoice = event => {
		event.preventDefault();
		const { referenceNumber, paymentType, phoneNumber, amountPaidKes } = state;
		if (!referenceNumber || !paymentType || !phoneNumber || !amountPaidKes) {
			setState({ ...state, formErrors: 'Payment type and all fields are required' });
			return;
		}
		dispatch(
			adminPayInvoiceAction(referenceNumber, phoneNumber, amountPaidKes, id, paymentType, adminGetInvoiceAction)
		);

		setState({ ...state, payInvoiceDialogOpen: false, formErrors: '' });
	};

	return (
		<>
			{invoiceFetched ? (
				<div id='single-invoice'>
					<Paper
						square={true}
						style={{
							background: 'rgba(0,0,0,0)',
							boxShadow: 'none',
							paddingLeft: mql.matches ? '0' : '240px',
							paddingTop: '10vh',
						}}
					>
						<Grid item xs={12}>
							<div className='invoice_card'>
								<Grid container spacing={0}>
									<Grid>
										<Paper className='invoice_head px-8 pt-12'>
											<Grid container spacing={0}>
												<Grid
													item
													xs={12}
													style={{
														background: '#F07829',
														color: 'white',
														fontStyle: 'bold',
														borderRadius: '8px',
													}}
													className='py-3 pl-5'
												>
													<span style={{ fontSize: '2.2em' }}>Invoice</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3}>
													<span>
														<Paper
															className='dashboard_clear_drawer_inv'
															style={{ cursor: 'default' }}
														></Paper>
													</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 3} style={{ marginTop: '15px' }}>
													<span>
														Vitumob Company Limited,
														<br />
														Storage Central Self-Storage 1st Floor,
														<br />
														Mombasa Road,
														<br />
														Nairobi.
													</span>
												</Grid>
												<Grid item xs={mql.matches ? 12 : 5} style={{ margin: '15px' }}>
													<table style={{ width: '100%' }}>
														<tr className='issued'>
															<th>Date Issued</th>
															<th>Invoice #</th>
														</tr>
														<tr>
															<td className='text-center'>
																{customDateFormatter(invoiceRdx.invoiceUpdatedOn)}
															</td>
															<td className='text-center'>
																{invoiceRdx.id.toUpperCase()}={' '}
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 5}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '90%' }}>
														<tr className='billed'>
															<th>Bill To:</th>
														</tr>
														<tr>
															<td className=''>
																{`${client.client && client.client.firstName} ${client.client && client.client.lastName}`}
																<br />
																{userAddressRdx.length > 0 &&
																	userAddressRdx[0].addressTown}
																,
																<br />
																{client.client && client.client.phoneNumber}
																<br />
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 5}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '90%' }}>
														<tr className='billed'>
															<th>Origin:</th>
														</tr>
														<tr>
															<td className=''>{invoiceRdx.country}</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 5}
													style={{ margin: '15px', marginTop: '30px' }}
												></Grid>
												<Grid item xs={12} style={{ margin: '15px' }}>
													{/* Box items */}
													{'consolidatedItems' in invoiceRdx &&
														invoiceRdx.consolidatedItems.length &&
														invoiceRdx.consolidatedItems.map((box, index) => {
															return (
																<table
																	key={Math.random()}
																	className='long-table'
																	style={{ width: '100%' }}
																>
																	<tr>
																		<th>
																			Box:&nbsp;<b>{index + 1}</b>
																			,&nbsp;&nbsp;&nbsp; Box weight:&nbsp;
																			<b>{box.weight} Kg</b>
																			,&nbsp;&nbsp;&nbsp; Total box items:&nbsp;
																			<b>{box.packages && box.packages.length}</b>
																		</th>
																	</tr>
																	{box.packages &&
																		box.packages.map((item, index) => {
																			return (
																				<tr
																					key={index}
																					className='long-table-height'
																				>
																					<td>
																						Tracking Number: &nbsp;
																						{item.trackingNumber}
																					</td>
																				</tr>
																			);
																		})}
																</table>
															);
														})}
													<table style={{ width: '100%', marginTop: '10px' }}>
														<tr className='long-table'>
															<th>Cost Category</th>
															<th>Debit</th>
															<th>Credit</th>
														</tr>
														<tr className='long-table-pad'>
															<td style={{ color: 'blue' }}>
																Total Weight - {state.totalKgs}&nbsp;Kg(s)
															</td>
															<td>
																Ksh. {addComma(invoiceRdx.weightCostKes)} - USD.{' '}
																{addComma(invoiceRdx.weightCostUsd)}
															</td>
															<td>--</td>
														</tr>
														<tr className='long-table-pad'>
															<td>Hazardous Goods Fee</td>
															<td>
																Ksh. {addComma(invoiceRdx.dangerousGoodsFeeKes)} - USD.{' '}
																{addComma(invoiceRdx.dangerousGoodsFeeUsd)}
															</td>
															<td>--</td>
														</tr>
														{/* {('dangerousGoodsFeeKes' in invoiceRdx ||
															'dangerousGoodsFeeUsd' in invoiceRdx) && (
															<tr>
																<td>Hazardous Goods Fee</td>
																<td>
																	Ksh. {invoiceRdx.dangerousGoodsFeeKes} - USD.{' '}
																	{invoiceRdx.dangerousGoodsFeeUsd}
																</td>
																<td>--</td>
															</tr>
														)} */}
														{invoiceRdx.debits &&
															invoiceRdx.debits.map(debit => {
																return (
																	<tr>
																		<td>{debit.description}</td>
																		<td>
																			Ksh. {addComma(debit.amountKes)} - USD.{' '}
																			{debit.amountUsd}
																		</td>
																		<td>--</td>
																	</tr>
																);
															})}
														{invoiceRdx.credits &&
															invoiceRdx.credits.map(credit => {
																return (
																	<tr>
																		<td>{credit.description}</td>
																		<td>--</td>
																		<td>
																			Ksh. {addComma(credit.amountKes)} - USD.{' '}
																			{credit.amountUsd}
																		</td>
																	</tr>
																);
															})}
														{invoiceRdx.payments &&
															invoiceRdx.payments.map(payment => {
																return (
																	<tr>
																		<td>Payment: {payment.paymentType}</td>
																		<td>--</td>
																		<td>
																			Ksh. {addComma(payment.amountPaidKes)} -
																			USD. {payment.amountPaidUsd}
																		</td>
																	</tr>
																);
															})}
														{invoiceRdx.storageCosts &&
															invoiceRdx.storageCosts.map(cost => {
																return (
																	<tr>
																		<td>
																			Storage Cost: {costFormatter(cost.addedOn)}
																		</td>
																		<td>
																			Ksh. {addComma(cost.amountKes)} - USD.{' '}
																			{cost.amountUsd}
																		</td>
																		<td>--</td>
																	</tr>
																);
															})}
													</table>
												</Grid>
												{/* <Grid item xs={mql.matches ? 12 : 7} style={{ margin: '15px', marginTop: '30px' }}>
										<div>
											<p>US Pricing: <br />Up to 2 kg - $25. <br />2 kg or more - $15 per kg.</p>
										</div>
										</Grid> */}
												<Grid
													item
													xs={mql.matches ? 12 : 7}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<div>
														<h4 style={{ color: '#F07829' }}>Terms & Conditions</h4>
														<p>
															For our full terms and conditions -{' '}
															<a
																href='/dashboard/terms_of_use'
																target='_blank'
																rel='noopener noreferrer'
															>
																here
															</a>
														</p>
													</div>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 4}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<table style={{ width: '90%' }} className='inverted'>
														<tr>
															<th>Invoice Total (USD)</th>
															<td>
																<span>{addComma(invoiceRdx.totalCostUsd)}</span>
															</td>
														</tr>
														<tr>
															<th>Invoice Total (KES)</th>
															<td>
																<span>{addComma(invoiceRdx.totalCostKes)}</span>
															</td>
														</tr>
													</table>
												</Grid>
												<Grid
													item
													xs={mql.matches ? 12 : 12}
													style={{ margin: '15px', marginTop: '30px' }}
												>
													<div>
														<p>
															Please initiate your payment through this invoice or if your
															like to pay via cash transfer please use the information
															below:
														</p>
														<p>
															<i>
																Account name: Vitumob Company Limited,
																<br />
																Bank: Equity Bank Ltd,
																<br />
																Branch: Fourways Towers
																<br />
																Kes Acc no. 0020260517036,
																<br />
																USD Acc no. 1450274915919,
															</i>
														</p>
														<p>
															<i>Call assistance: 0717 363433</i>
														</p>
													</div>
												</Grid>
												<Grid xs={12}>
													<Paper className='clean_paper' style={{ textAlign: 'right' }}>
														{invoiceRdx.paymentStatus === 'FULLY_PAID' ? (
															invoiceRdx.isDelivered ? (
																<Typography style={{ color: 'rgb(0,255,0)' }}>
																	PAID & DELIVERED
																</Typography>
															) : (
																<Typography style={{ color: 'rgb(0,255,0)' }}>
																	PAID
																</Typography>
															)
														) : (
															<div>
																<Button
																	onClick={() =>
																		setState({
																			...state,
																			changeCostDialogOpen: true,
																		})
																	}
																	color='primary'
																>
																	Add items
																</Button>
																<Button
																	onClick={() =>
																		setState({
																			...state,
																			payInvoiceDialogOpen: true,
																		})
																	}
																	color='secondary'
																>
																	Pay invoice
																</Button>
															</div>
														)}
													</Paper>
												</Grid>
											</Grid>
										</Paper>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Paper>
					{/* Reduce / Discount dialog */}
					<Dialog
						open={state.changeCostDialogOpen}
						onClose={() => setState({ ...state, changeCostDialogOpen: false })}
					>
						<DialogTitle>Add to or reduce from shipping cost</DialogTitle>
						<DialogContent>
							<Input
								name='description'
								onChange={onInputChange}
								value={state.description}
								placeholder='Description'
								fullWidth
							/>
						</DialogContent>
						<DialogContent>
							<Input
								name='amount'
								onChange={onInputChange}
								type='number'
								value={state.amount}
								placeholder='Amount (KES)'
								fullWidth
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => onExtraCosts('reduce')} color='primary'>
								Reduce from cost
							</Button>
							<Button onClick={() => onExtraCosts('add')} color='secondary'>
								Add to cost
							</Button>
						</DialogActions>
						<DialogContent style={{ color: 'orangered' }}>{state.addErrors}</DialogContent>
					</Dialog>
					{/* Pay invoice dialog
					 **********************
					 **********************
					 ********************/}
					<Dialog
						open={state.payInvoiceDialogOpen}
						onClose={() => setState({ ...state, payInvoiceDialogOpen: false })}
					>
						<DialogTitle>Pay invoice</DialogTitle>
						<form onSubmit={onPayInvoice}>
							<DialogContent>
								<InputLabel>Payment type</InputLabel>
								<FormControl>
									<RadioGroup
										name='paymentType'
										value={state.paymentType}
										onChange={onInputChange}
										row
									>
										<FormControlLabel
											value='MPESA'
											control={<Radio color='primary' />}
											label='MPesa'
										/>
										<FormControlLabel
											value='Check'
											control={<Radio color='primary' />}
											label='Check'
										/>
										<FormControlLabel
											value='BankTransfer'
											control={<Radio color='primary' />}
											label='Electronic funds transfer'
										/>
									</RadioGroup>
								</FormControl>
							</DialogContent>
							<DialogContent>
								<TextField
									name='referenceNumber'
									value={state.referenceNumber}
									onChange={onInputChange}
									variant='outlined'
									label='Payment reference number'
									required
									fullWidth
								/>
							</DialogContent>
							<DialogContent>
								<TextField
									name='phoneNumber'
									value={state.phoneNumber}
									onChange={onInputChange}
									variant='outlined'
									label='Phone number'
									required
									fullWidth
								/>
							</DialogContent>
							<DialogContent>
								<TextField
									name='amountPaidKes'
									value={state.amountPaidKes}
									onChange={onInputChange}
									variant='outlined'
									label='Amount paid in KES'
									required
									fullWidth
								/>
							</DialogContent>
							<DialogContent style={{ color: 'orangered' }}>{state.formErrors}</DialogContent>
							<DialogContent style={{ textAlign: 'right' }}>
								<Button type='submit' color='secondary'>
									Pay
								</Button>
							</DialogContent>
						</form>
					</Dialog>
					{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			) : (
				<div className='absolute top-1/2 left-1/2'>
					<Loader />
				</div>
			)}
		</>
	);
};
export default AdminInvoice;
