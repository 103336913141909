import { Grid, LinearProgress, makeStyles, Paper } from '@material-ui/core';
import { createSelector } from '@reduxjs/toolkit';
import Axios from 'axios';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from '../../../actions/DashAction';
import { useAppSelector } from '../../../store/store';
import { addComma } from '../../../utils/stringConverter';
import { useStyles } from '../../officer/localOfficer/drawer';

const mql = window.matchMedia(`(max-width: 1024px)`);

const OfficerReceipt = props => {
	// const [query, setQuery] = useState('');
	const { id } = useParams();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector([state => state.officer.client], userInfo => {
		return {
			userInfo,
		};
	});

	const { userInfo } = useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		userInfo: null,
		eligibleItems: [],
		receivedItems: [],
		shippedItems: [],
		notifications: [],
		receipts: [],
		receipt: null,
		notificationsDialogOpen: false,
		mobileScreen: mql.matches,
		page: 0,
		rowsPerPage: 10,
		filterLimit: 10,
		filterSkip: 0,
		receiptsCount: 0,
		paymentLimit: 0,
		paymentSkip: 0,
		packageReceipts: [],
		packageReceiptsCount: 0,
		loading: false,
	});

	const getReceipt = async () => {
		const payment = new URL(window.location.href).searchParams.get('payment');
		if (!payment.trim() || !id) {
			return navigate('/dashboard/receipts');
		}

		try {
			setState({
				...state,
				loading: true,
			});
			let data = (await Axios.get(`${baseUrl}/payments/${payment}/${id}`)).data;
			setState({
				...state,
				receipt: data,
				loading: false,
			});
		} catch (error) {
			setState({
				...state,
				loading: false,
			});
		}
	};

	useEffect(
		() => {
			getReceipt();
		},
		// eslint-disable-next-line
		[window.location.href, id]
	);

	useEffect(
		() => {
			setState({
				...state,
				userInfo,
			});
			console.log(userInfo);
		},
		// eslint-disable-next-line
		[userInfo]
	);

	return (
		<>
			{state.loading && <LinearProgress />}
			<Paper
				square={true}
				style={{
					background: 'rgba(0,0,0,0)',
					boxShadow: 'none',
					paddingLeft: mql.matches ? '0' : '240px',
					paddingTop: '10vh',
				}}
			>
				<div className='invoice_card' id='invoice_to_print'>
					{state.receipt && (
						<Grid spacing={1} xs={12}>
							<Paper className='invoice_head'>
								<Grid container spacing={0}>
									<Grid
										item
										xs={12}
										style={{
											background: '#303e52',
											paddingTop: '20px',
											color: 'white',
											fontStyle: 'bold',
										}}
									>
										<span style={{ fontSize: '2.2em', paddingLeft: '5px' }}>Receipt</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 6} style={{ marginTop: '15px' }}>
										<span>
											<Paper
												className='dashboard_clear_drawer_logo'
												style={{ cursor: 'default' }}
											></Paper>
										</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 6} style={{ marginTop: '15px' }}>
										<span>
											Vitumob Company Limited,
											<br />
											Storage Central Self-Storage 1st Floor8,
											<br />
											Mombasa Road,
											<br />
											Nairobi.
										</span>
									</Grid>
									<Grid item xs={mql.matches ? 12 : 8} style={{ margin: '15px' }}>
										<table style={{ width: '100%' }}>
											<tr className='issued'>
												<th>Date Paid</th>
												<th>Receipt #</th>
												<th>Client Number</th>
											</tr>
											<tr className='text-center'>
												<td>
													{state.receipt.paidOn &&
														format(new Date(state.receipt.paidOn), 'd MMMM, yyyy')}
													{state.receipt.paymentDate &&
														format(new Date(state.receipt.paymentDate), 'd MMMM, yyyy')}
												</td>
												<td>{state.receipt.id}</td>
												<td>
													{state.receipt.clientNumber &&
														state.receipt.clientNumber.toUpperCase()}
												</td>
											</tr>
										</table>
									</Grid>
									{state.userInfo && (
										<Grid
											item
											xs={mql.matches ? 12 : 8}
											style={{ margin: '15px', marginTop: '30px' }}
										>
											<table style={{ width: '90%' }}>
												<tr className='billed'>
													<th>Billed To:</th>
												</tr>

												<tr>
													<td className=''>
														{`${state.userInfo.firstName} ${state.userInfo.lastName}`}
														<br />

														{state.userInfo.phoneNumber}
														<br />
													</td>
												</tr>
											</table>
										</Grid>
									)}
									<Grid item xs={mql.matches ? 12 : 4} style={{ margin: '15px', marginTop: '30px' }}>
										<table style={{ width: '90%' }} className='inverted'>
											<tr>
												<th>Receipt Total ({state.receipt.foreignCurrency})</th>
												<td>
													<span>{state.receipt.amountPaid}</span>
												</td>
											</tr>
											<tr>
												<th>Receipt Total (KES)</th>
												<td>
													<span>{addComma(state.receipt.amountPaidKes)}</span>
												</td>
											</tr>
										</table>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					)}
				</div>
			</Paper>
		</>
	);
};

export default OfficerReceipt;
