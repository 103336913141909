import {
	Card,
	CardContent,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	LinearProgress,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { ExpandMoreOutlined } from '@material-ui/icons';
import { Avatar } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { adminGetClientAction, adminGetClientInvoicesAction } from '../../../actions/AdminActions';
import { Routes } from '../../../routes';
import { useAppSelector } from '../../../store/store';
import customDateFormatter from '../../../utils/customDateFormatter';
import stringConverter, { addComma } from '../../../utils/stringConverter';
import '../../dashboard/Dashboard.scss';
import { SanitizedItems } from '../../dashboard/registereditems/sanitizedItems';
import { HttpError, HttpSuccess } from '../../snackbars';
import '../Admin.scss';
import { useStyles } from '../drawer';

function filterInvoiceItems(collection, substring) {
	const invoices = _.filter(
		collection,
		_.flow(_.identity, _.values, _.join, _.toLower, _.partialRight(_.includes, substring.toLowerCase()))
	);
	return invoices;
}

const mql = window.matchMedia(`(max-width: 1024px)`);

const AdminClient = props => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.admin.clientsFetched,
			state => state.admin.client,
			state => state.admin.adminHttpErrors,
			state => state.admin.adminHttpSuccess,
			state => state.admin.userAddress,
			state => state.admin.addressFetched,
			state => state.admin.clientInvoicesFetched,
			state => state.admin.clientInvoices,
			state => state.admin.invoicesFetched,
		],
		(
			clientsFetched,
			client,
			adminHttpErrors,
			adminHttpSuccess,
			userAddress,
			addressFetched,
			clientInvoicesFetched,
			clientInvoices,
			invoicesFetched
		) => {
			return {
				clientsFetched,
				client,
				adminHttpErrors,
				adminHttpSuccess,
				userAddress,
				addressFetched,
				clientInvoicesFetched,
				clientInvoices,
				invoicesFetched,
			};
		}
	);

	const {
		clientsFetched,
		client,
		adminHttpErrors,
		adminHttpSuccess,
		userAddress,
		addressFetched,
		clientInvoicesFetched,
		clientInvoices,
		invoicesFetched,
	} = useAppSelector(selectProps);

	const [state, setState] = useState({
		clientInfo: {},
		clientItems: [],
		clientInvoices: [],
		filteredInvoices: [],
		filterString: '',
		userAddress: [],
	});

	const { id } = useParams();

	useEffect(
		() => {
			dispatch(adminGetClientAction(id));
			dispatch(adminGetClientInvoicesAction(id));
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (clientInvoicesFetched) {
				setState({ ...state, clientInvoices, filteredInvoices: clientInvoices });
			}

			if (addressFetched) {
				setState({ ...state, userAddress });
			}

			setState({ ...state, clientInfo: client, clientItems: client.client && client.client.packages });
		},
		// eslint-disable-next-line
		[client, clientInvoices, clientInvoicesFetched, userAddress, addressFetched]
	);

	const onFilterInvoices = substring => {
		setState({
			...state,
			filterString: substring,
			filteredInvoices: filterInvoiceItems(state.clientInvoices, substring),
		});
	};

	const headCells = [
		{ id: 'aclninvdate', numeric: true, disablePadding: false, label: 'Date', align: 'center' },
		{ id: 'aclninvinvoicno', numeric: true, disablePadding: false, label: 'Invoice No.', align: 'left' },
		{ id: 'aclinvcnt', numeric: true, disablePadding: false, label: 'Origin', align: 'left' },
		{ id: 'aclninvsts', numeric: true, disablePadding: false, label: 'Status', align: 'left' },
		{ id: 'aclninvbld', numeric: true, disablePadding: false, label: 'Balance Due', align: 'left' },
		{ id: 'aclninvact', numeric: true, disablePadding: false, label: 'View', align: 'left' },
	];

	const { clientInfo, clientItems } = state;
	const UnpaidInvoices = ({ invoices }) => {
		return (
			<TableBody className='table-body'>
				{invoices &&
					invoices.map((invoice, index) => {
						return (
							<TableRow
								hover
								tabIndex={-1}
								key={index}
								className={`table-row ${invoice.paymentStatus === 'FULLY_PAID' ? 'paid-row' : 'unpaid-row'}`}
							>
								<TableCell className='cell' align='center'>
									{customDateFormatter(invoice.invoiceUpdatedOn)}
								</TableCell>
								<TableCell className='cell' align='left'>
									{invoice.id.toUpperCase()}
								</TableCell>
								<TableCell className='cell' align='left'>
									{invoice.country}
								</TableCell>
								<TableCell className='cell' align='left'>
									{stringConverter(invoice.paymentStatus)}
								</TableCell>
								<TableCell className='cell' align='left'>
									<span>
										{invoice.foreignCurrency}. {invoice.totalCost} - KES.{' '}
										{addComma(invoice.totalCostKes)}
									</span>
								</TableCell>
								<TableCell className='clickable cell' align='left'>
									<button
										onClick={() => {
											navigate(Routes.AdminInvoice.replace(':id', `${invoice.id}`));
										}}
									>
										View
									</button>
								</TableCell>
							</TableRow>
						);
					})}
			</TableBody>
		);
	};

	const UnpaidInvoicesSmall = ({ invoices }) => {
		return (
			<Table style={{ display: mql.matches ? 'block' : 'none' }} className='table-mobi'>
				<TableBody className='table-body-mobi'>
					{invoices &&
						invoices.map((invoice, index) => {
							return (
								<TableRow
									key={index}
									className={`table-row-mobi ${invoice.paymentStatus === 'FULLY_PAID' ? 'paid-row' : 'unpaid-row'}`}
								>
									<TableCell className='cell'>
										<Typography className='pt-5'>
											<Avatar>
												<small>{index + 1}</small>
											</Avatar>
										</Typography>
										<Typography className='pt-7'>
											<b>Date:&nbsp;</b>
											{customDateFormatter(invoice.invoiceUpdatedOn)}
										</Typography>
										<Typography className='pt-2'>
											<b>Invoice No.:&nbsp;</b>
											{invoice.id.toUpperCase()}
										</Typography>
										<Typography className='pt-2'>
											<b>Origin:&nbsp;</b>
											{invoice.country}
										</Typography>
										<Typography className='pt-2'>
											<b>Status:&nbsp;</b>
											{stringConverter(invoice.paymentStatus)}
										</Typography>
										<Typography className='pt-2'>
											<b>Shipping cost:&nbsp;</b>
											{invoice.foreignCurrency}. {invoice.totalCost} - KES.{' '}
											{addComma(invoice.totalCostKes)}
										</Typography>
										<Typography className='pt-3 clickable'>
											<button
												onClick={() => {
													navigate(Routes.AdminInvoice.replace(':id', `${invoice.id}`));
												}}
											>
												View
											</button>
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		);
	};

	return (
		<>
			{!clientsFetched && !invoicesFetched ? (
				<div className='linear_progress'>
					<LinearProgress />
				</div>
			) : (
				<div>
					<Paper
						square={true}
						style={{
							background: 'rgba(0,0,0,0)',
							boxShadow: 'none',
							paddingLeft: mql.matches ? '0' : '240px',
							paddingTop: '10vh',
						}}
					>
						<Paper style={{ padding: '2%', margin: '2%' }}>
							<Typography variant='body1' style={{ padding: '0.5%' }}>
								<b>Client number:&nbsp;</b>
								{clientInfo.client && clientInfo.client.id && clientInfo.client.id.toUpperCase()}
							</Typography>
							<Typography variant='body1' style={{ padding: '0.5%' }}>
								<b>Name:&nbsp;</b>
								{(clientInfo.cleint && clientInfo.client.firstName) || ''}&nbsp;
								{(clientInfo.client && clientInfo.client.lastName) || ''}
							</Typography>
							<Typography variant='body1' style={{ padding: '0.5%' }}>
								<b>Mobile:&nbsp;</b>
								{clientInfo.client && clientInfo.client.phoneNumber}
								<br />
							</Typography>
							<Typography variant='body1' style={{ padding: '0.5%' }}>
								<b>Email:&nbsp;</b>
								{clientInfo.email}
								<br />
							</Typography>
							{state.userAddress.length > 0 && (
								<Typography variant='body1' style={{ padding: '0.5%' }}>
									<b>Addresses:&nbsp;</b>
									{state.userAddress.map(address => {
										return (
											<ExpansionPanel key={address.id}>
												<ExpansionPanelSummary expandIcon={<ExpandMoreOutlined />}>
													<Typography>{address.addressName}</Typography>
												</ExpansionPanelSummary>
												<ExpansionPanelDetails>
													Town:&nbsp;{address.addressTown}
													<br />
													Street:&nbsp;{address.addressStreet}
													<br />
													Building:&nbsp;{address.addressApartmentName}
													<br />
													Additional information:&nbsp;{address.addressOtherLocationInfo}
													<br />
												</ExpansionPanelDetails>
											</ExpansionPanel>
										);
									})}
									<br />
								</Typography>
							)}
							<Typography variant='body1' style={{ padding: '0.5%' }}>
								<b>Status:&nbsp;</b>
								{clientInfo.client && clientInfo.client.status}
								<br />
							</Typography>
							<Typography variant='body1' style={{ padding: '0.5%' }}>
								{/* <b>Date joined:&nbsp;</b>{Moment(clientInfo.createdAt).format('LLL')}<br/> */}
							</Typography>
						</Paper>
						{/* Client items */}
						<Card style={{ margin: '2%' }}>
							<CardContent>
								<SanitizedItems sorted={false} registeredItems={clientItems} />
							</CardContent>
						</Card>
						<Paper style={{ padding: '2%', margin: '2%' }} className='table-wrap'>
							<div className='tables'>
								{/* <Input
								value={state.filterString}
								placeholder='Search invoices'
								onChange={event => {
									onFilterInvoices(event.target.value);
								}}
								className={classes.searchField}
							/> */}
								<Table
									aria-labelledby='tableTitle'
									aria-label='enhanced table'
									className='table'
									style={{ display: mql.matches ? 'none' : 'blocsk' }}
								>
									<TableHead className='table-header table-header-bd'>
										<TableRow>
											{headCells.map(headCell => (
												<TableCell
													className='cell'
													key={headCell.id}
													align={headCell.align}
													padding={headCell.disablePadding ? 'none' : 'normal'}
												>
													{headCell.label}
												</TableCell>
											))}
										</TableRow>
									</TableHead>
									<UnpaidInvoices invoices={clientInvoices} />
								</Table>
								{/* Mobile screens */}
								<UnpaidInvoicesSmall invoices={clientInvoices} />
							</div>
						</Paper>
					</Paper>
					{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
					{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
				</div>
			)}
		</>
	);
};
export default AdminClient;
