import { Avatar, Checkbox, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Loader from '../loader/Loader';

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

const mql = window.matchMedia(`(max-width: 1024px)`);

const ShippingItemsTable = ({ shippingItems, order, orderBy, state, setState, onCheckPackage, fetchedData }) => {
	const headCells = [
		{ id: 'shpnnmbr', numeric: true, disablePadding: false, label: '', align: 'left' },
		{ id: 'shpnclnno', numeric: true, disablePadding: false, label: 'Client No.', align: 'left' },
		{ id: 'shpntrck', numeric: true, disablePadding: false, label: 'Tracking No.', align: 'left' },
		{ id: 'shpnshpnno', numeric: true, disablePadding: false, label: 'Shipping No.', align: 'left' },
		{ id: 'cntrynm', numeric: true, disablePadding: false, label: 'Origin', align: 'left' },
		{ id: 'shpndldt', numeric: true, disablePadding: false, label: 'Delivery Date', align: 'left' },
		{ id: 'shpnhzd', numeric: true, disablePadding: false, label: 'Harzadous', align: 'left' },
		{ id: 'shpnprt', numeric: true, disablePadding: false, label: 'Protected', align: 'left' },
		{ id: 'shpnsel', numeric: true, disablePadding: false, label: 'Select', align: 'left' },
	];

	const colors = [
		'orange',
		'orange',
		'orange',
		'orange',
		'orange',
		'orange',
		'orange',
		'orange',
		'orange',
		'blue',
		'red',
		'cyan',
		'magenta',
		'purple',
		'darkslategray',
		'green',
	];
	const states = [
		'IN_DELAWARE',
		'IN_UK',
		'IN_CHINA',
		'IN_CHINA_SEA',
		'IN_CHINA_SEA_GARMENT',
		'IN_TURKEY',
		'IN_TURKEY_SEA',
		'IN_DUBAI',
		'IN_DUBAI_SEA',
		'IN_TRANSIT',
		'IN_KENYA',
		'IN_NAIROBI',
		'BEING_PROCESSED',
		'AWAITING_PAYMENT',
		'PENDING_DELIVERY',
		'DELIVERED',
	];
	const colorIndex = status => states.findIndex(x => x === status);

	const items = shippingItems && stableSort(shippingItems, getComparator(order, orderBy));

	return (
		<div className='tables'>
			<Table
				aria-labelledby='tableTitle'
				aria-label='enhanced table'
				className='table'
				style={{ display: mql.matches ? 'none' : 'blocsk' }}
			>
				<TableHead className='table-header table-header-bd-sm'>
					<TableRow>
						{headCells.map(headCell => (
							<TableCell
								className='cell'
								key={headCell.id}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody className='table-body'>
					{items &&
						items.map((item, index) => {
							return (
								<TableRow
									key={item.id}
									style={{
										borderLeft: `2px solid ${colors[colorIndex(item.packageStatus)] || 'whitesmokes'}`,
									}}
								>
									<TableCell>{index + state.filterSkip + 1}</TableCell>
									<TableCell>{item.clientNumber && item.clientNumber.toUpperCase()}</TableCell>
									<TableCell
										style={{ color: 'blue', cursor: 'pointer' }}
										onClick={() => {
											setState({
												...state,
												packageStatus: !state.packageStatus,
												statuses: item.statusDates,
											});
										}}
									>
										{item.trackingNumber}
									</TableCell>
									<TableCell>{item.shipmentNumber}</TableCell>
									<TableCell>{item.country}</TableCell>
									<TableCell>{item.estimatedPackageArrivalDate}</TableCell>
									<TableCell>{item.isDangerous ? 'Yes' : 'No'}</TableCell>
									<TableCell>{item.isShipmentProtected ? 'Yes' : 'No'}</TableCell>
									<TableCell>
										<Checkbox
											color='primary'
											checked={item.arrived}
											onClick={event => onCheckPackage(event, item.id)}
										/>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			{/* Mobile screens */}
			<Table style={{ display: mql.matches ? 'block' : 'none' }} className='table-mobi'>
				<TableBody className='table-body-mobi'>
					{items &&
						items.map((item, index) => {
							return (
								<TableRow
									key={index}
									style={{
										borderLeft: `2px solid ${colors[colorIndex(item.packageStatus)] || 'whitesmokes'}`,
									}}
									className='table-row-mobi'
								>
									<TableCell className='cell'>
										<Typography className='pt-5'>
											<Avatar>
												<small>{index + 1}</small>
											</Avatar>
										</Typography>
										<Typography className='pt-7'>
											<b>Client number:&nbsp;</b>
											{item.clientNumber && item.clientNumber.toUpperCase()}
										</Typography>
										<Typography
											className='pt-2'
											style={{ color: 'blue', cursor: 'pointer' }}
											onClick={() => {
												setState({
													...state,
													packageStatus: !state.packageStatus,
													statuses: item.statusDates,
												});
											}}
										>
											<b>Tracking Number:&nbsp;</b>
											{item.trackingNumber}
										</Typography>
										<Typography className='pt-2'>
											<b>Shipment Number:&nbsp;</b>
											{item.shipmentNumber}
										</Typography>
										<Typography className='pt-2'>
											<b>Origin:&nbsp;</b>
											{item.country}
										</Typography>
										<Typography className='pt-2'>
											<b>Harzardous:&nbsp;</b>
											{item.isDangerous ? 'Yes' : 'No'}
										</Typography>
										<Typography className='pt-2'>
											<b>Protected:&nbsp;</b>
											{item.isShipmentProtected ? 'Yes' : 'No'}
										</Typography>
										<Typography className='pt-2'>
											<b>Delivery Date:&nbsp;</b>
											{item.estimatedPackageArrivalDate}
										</Typography>
										<div className='pt-2'>
											<b>Select:&nbsp;</b>
											<Checkbox
												color='primary'
												checked={item.arrived}
												onClick={event => onCheckPackage(event, item.id)}
											/>
										</div>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			{!fetchedData &&
				(mql.matches ? (
					<div className='absolute top-1/2 flex w-full justify-around'>
						<Loader />
					</div>
				) : (
					<div className='absolute top-1/2 left-1/2'>
						<Loader />
					</div>
				))}
		</div>
	);
};

export default ShippingItemsTable;
