import { Avatar, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { createSelector } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/store';
import customDateFormatter from '../../../utils/customDateFormatter';
import stringConverter, { addComma } from '../../../utils/stringConverter';

const mql = window.matchMedia(`(max-width: 1024px)`);

const ReceiptsTable = ({ receipts, shipment }) => {
	const navigate = useNavigate();
	const selectProps = createSelector(
		[state => state.auth.isAdmin, state => state.auth.isOfficer, state => state.auth.isClient],
		(isAdmin, isOfficer, isClient) => {
			return {
				isAdmin,
				isOfficer,
				isClient,
			};
		}
	);
	const { isAdmin, isOfficer, isClient } = useAppSelector(selectProps);

	const headCells = [
		{ id: 'rctsclnno', numeric: true, disablePadding: true, label: '', align: 'center' },
		{ id: 'rctsdt', numeric: true, disablePadding: false, label: 'Date', align: 'left' },
		{ id: 'rctsrfno', numeric: true, disablePadding: false, label: 'Reference No.', align: 'left' },
		{ id: 'rctsamnt', numeric: true, disablePadding: false, label: 'Amount', align: 'left' },
		{ id: 'rctspymtyp', numeric: true, disablePadding: false, label: 'Payment Type', align: 'left' },
		{ id: 'rctsvw', numeric: true, disablePadding: false, label: 'View', align: 'left' },
	];

	return (
		<div className='tables'>
			<Table
				aria-labelledby='tableTitle'
				aria-label='enhanced table'
				className='table'
				style={{ display: mql.matches ? 'none' : 'blocsk' }}
			>
				<TableHead className='table-header'>
					<TableRow>
						{headCells.map(headCell => (
							<TableCell
								className='cell'
								key={headCell.id}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
							>
								{headCell.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody className='table-body'>
					{receipts &&
						receipts.map((receipt, index) => {
							return (
								<TableRow hover tabIndex={-1} key={index} className={`table-row`}>
									<TableCell align='center'>{index + 1}</TableCell>
									<TableCell className='cell' align='left'>
										{receipt.paidOn && customDateFormatter(receipt.paidOn)}
										{receipt.paymentDate && customDateFormatter(receipt.paymentDate)}
									</TableCell>
									<TableCell className='cell' align='left'>
										{receipt.referenceNumber}
									</TableCell>
									<TableCell className='cell' align='left'>
										{receipt.foreignCurrency}. {receipt.amountPaid}&nbsp;&nbsp;-&nbsp; KES.{' '}
										{addComma(receipt.amountPaidKes)}
									</TableCell>
									<TableCell className='cell' align='left'>
										{stringConverter(receipt.paymentType || '')}
									</TableCell>
									<TableCell className='clickable cell' align='left'>
										<button
											onClick={() => {
												if (shipment) {
													if (isAdmin) {
														navigate(`/admin/receipts/${receipt.id}?payment=packages`);
													}
													if (isOfficer) {
														navigate(
															`/officers/officer/receipts/${receipt.id}?payment=packages`
														);
													}
													if (isClient) {
														navigate(`/dashboard/receipts/${receipt.id}?payment=packages`);
													}
												} else {
													if (isAdmin) {
														navigate(`/admin/receipts/${receipt.id}?payment=invoice`);
													}
													if (isOfficer) {
														navigate(
															`/officers/officer/receipts/${receipt.id}?payment=invoice`
														);
													}
													if (isClient) {
														navigate(`/dashboard/receipts/${receipt.id}?payment=invoice`);
													}
												}
											}}
										>
											View
										</button>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
			{/* Mobile screens */}
			<Table style={{ display: mql.matches ? 'block' : 'none' }} className='table-mobi'>
				<TableBody className='table-body-mobi'>
					{receipts &&
						receipts.map((receipt, index) => {
							return (
								<TableRow key={index} className='table-row-mobi'>
									<TableCell className='cell'>
										<Typography className='pt-5'>
											<Avatar>
												<small>{index + 1}</small>
											</Avatar>
										</Typography>
										<Typography className='pt-7'>
											<b>Date:&nbsp;</b>
											{receipt.paidOn && customDateFormatter(receipt.paidOn)}
											{receipt.paymentDate && customDateFormatter(receipt.paymentDate)}
										</Typography>
										<Typography className='pt-2'>
											<b>Reference No:&nbsp;</b>
											{receipt.referenceNumber}
										</Typography>
										<Typography className='pt-2'>
											<b>Amount:&nbsp;</b>
											{receipt.foreignCurrency}. {receipt.amountPaid}&nbsp;&nbsp;-&nbsp; KES.{' '}
											{addComma(receipt.amountPaidKes)}
										</Typography>
										<Typography className='pt-2'>
											<b>Payment Type:&nbsp;</b>
											{stringConverter(receipt.paymentType || '')}
										</Typography>
										<Typography className='pt-3 clickable'>
											<button
												onClick={() => {
													if (shipment) {
														if (isAdmin) {
															navigate(`/admin/receipts/${receipt.id}?payment=packages`);
														}
														if (isOfficer) {
															navigate(
																`/officers/officer/receipts/${receipt.id}?payment=packages`
															);
														}
														if (isClient) {
															navigate(
																`/dashboard/receipts/${receipt.id}?payment=packages`
															);
														}
													} else {
														if (isAdmin) {
															navigate(`/admin/receipts/${receipt.id}?payment=invoice`);
														}
														if (isOfficer) {
															navigate(
																`/officers/officer/receipts/${receipt.id}?payment=invoice`
															);
														}
														if (isClient) {
															navigate(
																`/dashboard/receipts/${receipt.id}?payment=invoice`
															);
														}
													}
												}}
											>
												View
											</button>
										</Typography>
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>
		</div>
	);
};

export default ReceiptsTable;
