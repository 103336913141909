const initialState = {
	userInfo: {},
	userAddress: [],
	recentUploads: [],
	recentUpload: {},
	messages: [],
	receipts: [],
	receiptsCount: 0,
	packageReceipts: [],
	packageReceiptsCount: 0,
	invoices: [],
	invoicesCount: 0,
	invoice: {},
	userInfoFetched: false,
	userProfileInfoUpdated: false,
	itemUploadSuccess: false,
	recentUploadsSuccess: false,
	recentUploadSuccess: false,
	recentItemEdited: false,
	invoicePaid: false,
	invoicesSuccess: false,
	invoiceSuccess: false,
	exchangeRates: {},
	messagesFetched: false,
	messageActionsSucces: false,
	passwordChangeSuccess: false,
	addressAdded: false,
	protectionPaid: false,
	fetchingData: false,
	errorMsg: null,
	successMsg: null,
	calculationVariables: null,
	fetchingVariables: false,
};
export const dashReducer = (state = initialState, action) => {
	switch (action.type) {
		/* Fetch user info */
		case 'DASH_GET_USER_INFO':
			return {
				...state,
				fetchingData: true,
				userInfoFetched: false,
				userProfileInfoUpdated: false,
				passwordChangeSuccess: false,
				userInfo: {},
				userAddress: [],
				// exchangeRates: {},
				addressAdded: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_GET_USER_INFO_SUCCESS':
			return {
				...state,
				userInfo: action.payload.userInfo,
				userAddress: action.payload.userAddress,
				// exchangeRates: action.payload.exchangeRates,
				userInfoFetched: true,
				fetchingData: false,
			};
		/* Update user profile */
		case 'DASH_UPDATE_USER_INFO':
			return {
				...state,
				fetchingData: true,
				userProfileInfoUpdated: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_UPDATE_USER_INFO_SUCCESS':
			return {
				...state,
				fetchingData: false,
				successMsg: action.payload,
				userProfileInfoUpdated: true,
			};
		case 'DASH_GET_VARIABLES':
			return {
				...state,
				fetchingVariables: true,
				calculationVariables: null,
			};
		case 'DASH_GET_VARIABLES_SUCCESS':
			return {
				...state,
				fetchingVariables: false,
				calculationVariables: action.payload,
			};
		/* Change user password */
		case 'DASH_CHANGE_PASSWORD':
			return {
				...state,
				fetchingData: true,
				passwordChangeSuccess: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_CHANGE_PASSWORD_SUCCESS':
			return {
				...state,
				fetchingData: false,
				passwordChangeSuccess: true,
				successMsg: action.payload,
			};
		/* Bulk item upload */
		case 'DASH_BULK_UPLOAD':
			return {
				...state,
				fetchingData: true,
				itemUploadSuccess: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_BULK_UPLOAD_SUCCESS':
			return {
				...state,
				fetchingData: false,
				itemUploadSuccess: true,
				successMsg: action.payload,
			};
		/* Recent uploads */
		case 'DASH_RECENT_UPLOADS':
			return {
				...state,
				fetchingData: true,
				itemUploadSuccess: false,
				recentUploadsSuccess: false,
				recentItemEdited: false,
				recentUploads: [],
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_RECENT_UPLOADS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				recentUploadsSuccess: true,
				recentUploads: action.payload.client.packages,
			};
		case 'DASH_RECENT_UPLOAD':
			return {
				...state,
				fetchingData: true,
				itemUploadSuccess: false,
				recentUploadSuccess: false,
				recentItemEdited: false,
				recentUpload: {},
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_RECENT_UPLOAD_SUCCESS':
			return {
				...state,
				fetchingData: false,
				recentUploadSuccess: true,
				recentUpload: action.payload,
			};
		case 'DASH_EDIT_RECENT_UPLOAD':
			return {
				...state,
				fetchingData: true,
				recentItemEdited: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_EDIT_RECENT_UPLOAD_SUCCESS':
			return {
				...state,
				fetchingData: false,
				recentItemEdited: true,
				successMsg: action.payload,
			};
		case 'DASH_INVOICES':
			return {
				...state,
				fetchingData: true,
				successMsg: null,
				errorMsg: null,
				invoicesSuccess: false,
				invoiceSuccess: false,
				invoices: [],
				invoicesCount: 0,
			};
		case 'DASH_INVOICES_SUCCESS':
			return {
				...state,
				fetchingData: false,
				invoicesSuccess: true,
				invoices: action.payload.invoices,
				invoicesCount: action.payload.count.count,
			};
		case 'DASH_RECEIPTS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				invoicesSuccess: true,
				receipts: action.payload.receipts,
				receiptsCount: action.payload.count.count,
			};
		case 'DASH_PACKAGE_RECEIPTS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				invoicesSuccess: true,
				packageReceipts: action.payload.packageReceipts,
				packageReceiptsCount: action.payload.count.count,
			};
		case 'DASH_INVOICE':
			return {
				...state,
				fetchingData: true,
				successMsg: null,
				errorMsg: null,
				invoiceSuccess: false,
				invoicesSuccess: false,
				invoice: {},
			};
		case 'DASH_INVOICE_SUCCESS':
			return {
				...state,
				fetchingData: false,
				invoiceSuccess: true,
				invoice: action.payload,
			};
		case 'DASH_RECEIPT_SUCCESS':
			return {
				...state,
				fetchingData: false,
				invoiceSuccess: true,
				invoice: action.payload,
			};
		case 'DASH_PAY_INVOICE':
			return {
				...state,
				fetchingData: true,
				invoicePaid: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_PAY_INVOICE_SUCCESS':
			return {
				...state,
				fetchingData: false,
				successMsg: action.payload,
				invoicePaid: true,
			};
		/* Protection payment */
		case 'DASH_PAY_PROTECTION':
			return {
				...state,
				fetchingData: true,
				protectionPaid: false,
				errorMsg: null,
				successMsg: null,
			};
		case 'DASH_PAY_PROTECTION_SUCCESS':
			return {
				...state,
				fetchingData: false,
				successMsg: action.payload.CustomerMessage,
				protectionPaid: true,
			};
		/* messages */
		case 'DASH_MESSAGES':
			return {
				...state,
				fetchingData: true,
				messages: [],
				messagesFetched: false,
				messageActionsSucces: false,
				successMsg: null,
				errorMsg: null,
			};
		case 'DASH_MESSAGES_SUCCESS':
			return {
				...state,
				fetchingData: false,
				messages: action.payload,
				messagesFetched: true,
			};
		case 'DASH_MESSAGES_ACTIONS':
			return {
				...state,
				fetchingData: true,
				messageActionsSucces: false,
				successMsg: null,
				errorMsg: null,
			};
		case 'DASH_MESSAGES_ACTIONS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				messageActionsSucces: true,
				successMsg: action.payload,
			};
		case 'DASH_ADD_ADDRESS':
			return {
				...state,
				fetchingData: true,
				addressAdded: false,
				successMsg: null,
				errorMsg: null,
			};
		case 'DASH_ADD_ADDRESS_SUCCESS':
			return {
				...state,
				fetchingData: false,
				addressAdded: true,
				successMsg: action.payload,
			};
		case 'DASH_UPDATE_PRICE':
			return {
				...state,
			};
		case 'DASH_UPDATE_PRICE_SUCCESS':
			return {
				...state,
			};
		case 'DASH_UPDATE_STATUS':
			return {
				...state,
			};
		case 'DASH_UPDATE_STATUS_SUCCESS':
			return {
				...state,
			};
		/* Default */
		default:
			return {
				...state,
			};
	}
};
