import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	Tooltip,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { createSelector } from '@reduxjs/toolkit';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import {
	adminGetClientsCountAction,
	adminGetClientsLatestAction,
	adminGetPackageAnalyticsAction,
	adminGetPaymentAnalyticsAction,
	getRevenueAction,
} from '../../../actions/AdminActions';
import '../../../components/admin/Admin.scss';
import { useAppSelector } from '../../../store/store';
import '../../dashboard/Dashboard.scss';
import { HttpError, HttpSuccess } from '../../snackbars';
// import Stats, { Stats1 } from './Stats';
import { CubeIcon, CurrencyDollarIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import { format } from 'date-fns';
import StatusBullet from '../../../components/admin/StatusBullet';
import { useStyles } from '../../../components/admin/drawer';
import customDateFormatter from '../../../utils/customDateFormatter';
import { addComma } from '../../../utils/stringConverter';
import Stats, { Stats1 } from '../../admin/Stats';
import Loader from '../../common/loader/Loader';
const mql = window.matchMedia(`(max-width: 1024px)`);

const statusColors = {
	active: 'success',
	inactive: 'warning',
	dormant: 'dark',
	'pending-registration': 'darklight',
};

const OfficerStats = props => {
	const dispatch = useDispatch();
	const classes = makeStyles(useStyles)();

	const selectProps = createSelector(
		[
			state => state.admin.metrics,
			state => state.admin.metricsFetched,
			state => state.admin.revenue,
			state => state.admin.fetchedRevenue,
			state => state.admin.fetchedPayments,
			state => state.admin.payments,
			state => state.admin.revenueJSON,
			state => state.admin.fetchedPaymentAnalytics,
			state => state.admin.paymentAnalytics,
			state => state.admin.fetchedPackageAnalytics,
			state => state.admin.packageAnalytics,
			state => state.admin.clientsCounts,
			state => state.admin.fetchedClientsCount,
			state => state.admin.fetchedClientsLatest,
			state => state.admin.clientsLatest,
			state => state.admin.adminHttpErrors,
			state => state.admin.adminHttpSuccess,
		],
		(
			metrics,
			metricsFetched,
			revenue,
			fetchedRevenue,
			fetchedPayments,
			payments,
			revenueJSON,
			fetchedPaymentAnalytics,
			paymentAnalytics,
			fetchedPackageAnalytics,
			packageAnalytics,
			clientsCounts,
			fetchedClientsCount,
			fetchedClientsLatest,
			clientsLatest,
			adminHttpErrors,
			adminHttpSuccess
		) => {
			return {
				metrics,
				metricsFetched,
				revenue,
				fetchedRevenue,
				fetchedPayments,
				payments,
				revenueJSON,
				fetchedPaymentAnalytics,
				paymentAnalytics,
				fetchedPackageAnalytics,
				packageAnalytics,
				clientsCounts,
				fetchedClientsCount,
				fetchedClientsLatest,
				clientsLatest,
				adminHttpErrors,
				adminHttpSuccess,
			};
		}
	);

	const {
		metrics,
		metricsFetched,
		revenue,
		fetchedRevenue,
		fetchedPayments,
		payments,
		revenueJSON,
		fetchedPaymentAnalytics,
		paymentAnalytics,
		fetchedPackageAnalytics,
		packageAnalytics,
		clientsCounts,
		fetchedClientsCount,
		fetchedClientsLatest,
		clientsLatest,
		adminHttpErrors,
		adminHttpSuccess,
	} = useAppSelector(selectProps);

	const [state, setState] = useState({
		drawerOpen: false,
		profileAnchorEl: null,
		metrics: {},
		startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
		endDate: new Date(),
		dateError: null,
		selectedStartDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
		selectedEndDate: new Date(),
		csvData: [],
		payments: '',
		filterLimit: 10,
		filterSkip: 0,
		page: 0,
		rowsPerPage: 10,
		revenueCount: 0,
		revenueJSON: [],
		revenueCSV: '',
		packageAnalytics: [],
		paymentAnalytics: [],
		clientsCounts: {},
		activeYear: new Date().getFullYear(),
		activeYear1: new Date().getFullYear(),
		clientsLatest: null,
	});

	useEffect(
		() => {
			const { selectedStartDate, selectedEndDate, filterLimit, filterSkip } = state;
			dispatch(getRevenueAction(selectedStartDate, selectedEndDate, filterLimit, filterSkip));
			dispatch(adminGetPaymentAnalyticsAction());
			dispatch(adminGetPackageAnalyticsAction());
			dispatch(adminGetClientsCountAction());
			dispatch(adminGetClientsLatestAction());
			// log vitumob pay id
			const code = process.env.REACT_APP_FATTMERCHANT;
			console.log('VM Code: ', code);
		},
		// eslint-disable-next-line
		[]
	);

	useEffect(
		() => {
			if (fetchedClientsLatest) {
				setState({ ...state, clientsLatest });
			}
			if (fetchedClientsCount) {
				setState({ ...state, clientsCounts });
			}
			if (fetchedPackageAnalytics) {
				setState({ ...state, packageAnalytics });
			}
			if (fetchedPaymentAnalytics) {
				setState({ ...state, paymentAnalytics });
			}
			if (metricsFetched) {
				setState({ ...state, metrics });
			}
			if (fetchedRevenue) {
				setState({ ...state, csvData: revenue, revenueCount: revenue.length, revenueJSON });
				let headers = {
					invoiceNumber: 'Invoice Number',
					amountUsd: 'Amount (USrenD)',
					amountKes: 'Amount (KES)',
					paymentType: 'Payment Type',
					referenceNumber: 'Reference Number',
					phoneNumber: 'Phone Number',
					paidOn: 'Paid On',
				};

				let revenues = revenueJSON.map(revenue => {
					return {
						invoiceNumber: `${revenue.invoiceNumber.toUpperCase()}`,
						amountUsd: `${revenue.amountPaidUsd}`,
						amountKes: `${revenue.amountPaidKes}`,
						paymentType: `${revenue.paymentType}`,
						referenceNumber: `${revenue.referenceNumber}`,
						phoneNumber: `${revenue.phoneNumber}`,
						paidOn: `${revenue.paidOn && customDateFormatter(revenue.paidOn)}`,
					};
				});
				revenues.unshift(headers);

				setState({
					...state,
					revenueCSV: ConvertToCSV(revenues),
				});
			}
			if (fetchedPayments) {
				setState({ ...state, payments });
			}
		},
		// eslint-disable-next-line
		[
			metrics,
			metricsFetched,
			revenue,
			fetchedRevenue,
			fetchedPayments,
			payments,
			revenueJSON,
			fetchedPaymentAnalytics,
			paymentAnalytics,
			fetchedPackageAnalytics,
			packageAnalytics,
			clientsCounts,
			fetchedClientsCount,
			fetchedClientsLatest,
			clientsLatest,
		]
	);

	// Convert JSON to CSV Function
	const ConvertToCSV = objArray => {
		let array = objArray;
		let str = '';

		for (let i = 0; i < array.length; i++) {
			let line = '';
			for (let index in array[i]) {
				if (line !== '') line += ',';

				line += array[i][index];
			}

			str += line + '\r\n';
		}

		return str;
	};

	const setActiveYear = value => {
		setState({ ...state, activeYear: value });
	};

	const setActiveYear1 = value => {
		setState({ ...state, activeYear1: value });
	};

	const formatPackageStatus = S => {
		let stringArray = S.split('');
		for (let i = 0; i < S.length; i++) {
			if (S.charAt(i) === '_') {
				stringArray[i] = ' ';
			}
		}
		return stringArray.join('');
	};

	// const formatNumber = number => {
	// 	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	// };

	// const onChangeStartDate = date => {
	// 	setState({ ...state, dateError: null });
	// 	const { endDate } = state;
	// 	if (Moment(date).isBefore(endDate)) {
	// 		setState({ ...state, startDate: Moment(date) }, () => {
	// 			dispatch(adminGetMetricsAction(date, endDate));
	// 		});
	// 	} else {
	// 		setState({ ...state, dateError: 'Start date should be any day before the end date' });
	// 	}
	// };

	// const onChangeSelectStart = date => {
	// 	setState({ ...state, dateError: null });
	// 	const { selectedEndDate, filterLimit, filterSkip } = state;
	// 	if (Moment(date).isBefore(selectedEndDate)) {
	// 		setState({ ...state, selectedStartDate: Moment(date) }, () => {
	// 			dispatch(getRevenueAction(date, selectedEndDate, filterLimit, filterSkip));
	// 		});
	// 	} else {
	// 		setState({ ...state, dateError: 'Start date should be any day before the end date' });
	// 	}
	// };

	// const onChangeSelectEnd = date => {
	// 	setState({ ...state, dateError: null });
	// 	const { selectedStartDate, filterLimit, filterSkip } = state;
	// 	if (Moment(date).isBefore(Moment())) {
	// 		if (Moment(date).isAfter(selectedStartDate)) {
	// 			setState({ ...state, selectedEndDate: Moment(date) }, () => {
	// 				dispatch(getRevenueAction(date, selectedEndDate, filterLimit, filterSkip));
	// 			});
	// 		} else {
	// 			setState({ ...state, dateError: 'The end date cannot be after the start date' });
	// 		}
	// 	} else {
	// 		setState({ ...state, dateError: 'Oops! we cannot tell the future' });
	// 	}
	// };

	// const onChangeEndDate = date => {
	// 	setState({ ...state, dateError: null });
	// 	const { startDate } = state;
	// 	if (Moment(date).isBefore(Moment())) {
	// 		if (Moment(date).isAfter(startDate)) {
	// 			setState({ ...state, endDate: Moment(date) }, () => {
	// 				dispatch(adminGetMetricsAction(startDate, date));
	// 			});
	// 		} else {
	// 			setState({ ...state, dateError: 'The end date cannot be after the start date' });
	// 		}
	// 	} else {
	// 		setState({ ...state, dateError: 'Oops! we cannot tell the future' });
	// 	}
	// };

	// const handleDateChange = date => {
	// 	setState({ ...state, csvData: [] });
	// 	setState({ ...state, selectedDate: Moment(date) }, () => {
	// 		const { selectedDate } = state;
	// 		const payload = {};
	// 		payload.month = selectedDate.month().toString();
	// 		payload.year = selectedDate.year().toString();
	// 		dispatch(getRevenueAction(payload));
	// 	});
	// };

	const handleChangePage = (event, newPage) => {
		const { filterLimit } = state;

		setState({ ...state, page: newPage, filterSkip: newPage * filterLimit }, () => {
			const { selectedStartDate, selectedEndDate, filterLimit, filterSkip } = state;
			dispatch(getRevenueAction(selectedStartDate, selectedEndDate, filterLimit, filterSkip));
		});
	};

	const yearlyPackageCount = () => {
		let count = 0;
		if (packageAnalytics.length > 0) {
			const map = packageAnalytics.filter(pkg => pkg.year === `${state.activeYear}`).map(pkg => pkg.packageCount);
			count = map.reduce((a, b) => Number(a) + Number(b), 0);
		}
		return count;
	};

	const yearlyIncomeCount = () => {
		let count = 0;
		if (paymentAnalytics.length > 0) {
			const map = paymentAnalytics.filter(pkg => pkg.year === `${state.activeYear1}`).map(pkg => pkg.amountInKsh);
			count = map.reduce((a, b) => Number(a) + Number(b), 0);
		}
		return count;
	};

	// const { startDate, endDate } = state
	let revenueLarge;
	let revenueSmall;
	revenueLarge =
		state.csvData.length > 0 &&
		state.csvData.map(revenue => {
			return (
				<TableRow key={revenue.id}>
					<TableCell>{revenue.invoiceNumber && revenue.invoiceNumber.toUpperCase()}</TableCell>
					<TableCell>{revenue.amountPaidUsd.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
					<TableCell>{revenue.amountPaidKes.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
					<TableCell>{revenue.paymentType}</TableCell>
					<TableCell>{revenue.referenceNumber}</TableCell>
					<TableCell>{revenue.phoneNumber}</TableCell>
					<TableCell>{revenue.paidOn && customDateFormatter(revenue.paidOn)}</TableCell>
				</TableRow>
			);
		});
	revenueSmall =
		state.csvData.length > 0 &&
		state.csvData.map(revenue => {
			return (
				<TableRow key={revenue.id}>
					<TableCell>
						<Typography>
							<b>Invoice Number:&nbsp;</b>
							{revenue.invoiceNumber && revenue.invoiceNumber.toUpperCase()}
						</Typography>
						<Typography>
							<b>Amount (USD):&nbsp;</b>
							{revenue.amountPaidUsd.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Typography>
						<Typography>
							<b>Amount (KES):&nbsp;</b>
							{revenue.amountPaidKes.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Typography>
						<Typography>
							<b>Payment Type:&nbsp;</b>
							{revenue.paymentType}
						</Typography>
						<Typography>
							<b>Reference Number:&nbsp;</b>
							{revenue.referenceNumber}
						</Typography>
						<Typography>
							<b>Phone Number:&nbsp;</b>
							{revenue.phoneNumber}
						</Typography>
						<Typography>
							<b>Paid On:&nbsp;</b>
							{revenue.paidOn && customDateFormatter(revenue.paidOn)}
						</Typography>
					</TableCell>
				</TableRow>
			);
		});

	return (
		<>
			<div>
				<Paper
					square={true}
					style={{
						background: 'rgba(0,0,0,0)',
						boxShadow: 'none',
						paddingLeft: mql.matches ? '0' : '240px',
						paddingTop: '16vh',
					}}
				>
					{!fetchedClientsLatest && (
						<div className='absolute top-1/2 left-1/2'>
							<Loader />
						</div>
					)}
					<Grid container spacing={0}>
						<div style={{ margin: '0 0 10px 15px' }}>
							<Typography className='title-text-style'>Stats</Typography>
						</div>
						{/* Top Cards */}
						<Grid container spacing={2} style={{ margin: '0 5px 5px 5px' }}>
							<div class='px-6 py-2 w-full block lg:flex'>
								<div class='bg-white rounded-lg border border-gray-100 p-6 shadow-md shadow-black/5 w-full mr-3'>
									<div class='flex justify-between'>
										<div>
											<Typography class='text-sm font-medium text-gray-400'>Income</Typography>
											<Typography class='text-2xl font-semibold mt-3 text-gray-700'>{`Kshs. ${addComma(yearlyIncomeCount())}`}</Typography>
										</div>
										<div>
											<div class='flex items-center mt-5'>
												<div className='p-3 bg-orange-100 rounded-full'>
													<CurrencyDollarIcon className='text-orange-500 h-10 w-10' />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class='bg-white rounded-lg border border-gray-100 p-6 shadow-md shadow-black/5 w-full'>
									<div class='flex justify-between'>
										<div>
											<Typography class='text-sm font-medium text-gray-400'>Packages</Typography>
											<Typography class='text-2xl font-semibold mt-3 text-gray-700'>{`${addComma(yearlyPackageCount())}`}</Typography>
										</div>
										<div>
											<div class='flex items-center mt-5'>
												<div className='p-3 bg-red-100 rounded-full'>
													<CubeIcon className='text-red-500 h-10 w-10' />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class='px-6 py-2 w-full block lg:flex'>
								<div class='bg-white rounded-lg border border-gray-100 p-6 shadow-md shadow-black/5 w-full mr-3'>
									<div class='flex justify-between'>
										<div>
											<Typography class='text-sm font-medium text-gray-400'>
												Total Clients
											</Typography>
											<Typography class='text-2xl font-semibold mt-3 text-gray-700'>
												{fetchedClientsCount && `${addComma(clientsCounts.all)}`}
											</Typography>
										</div>
										<div>
											<div class='flex items-center mt-5'>
												<div className='p-3 bg-green-100 rounded-full'>
													<UserGroupIcon className='text-green-500 h-10 w-10' />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class='bg-white rounded-lg border border-gray-100 p-6 shadow-md shadow-black/5 w-full mr-3'>
									<div class='flex justify-between'>
										<div>
											<Typography class='text-sm font-medium text-gray-400'>
												Active Clients
											</Typography>
											<Typography class='text-2xl font-semibold mt-3 text-gray-700'>
												{fetchedClientsCount && `${addComma(clientsCounts.active)}`}
											</Typography>
										</div>
										<div>
											<div class='flex items-center mt-5'>
												<div className='p-3 bg-blue-100 rounded-full'>
													<UserGroupIcon className='text-blue-500 h-10 w-10' />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class='bg-white rounded-lg border border-gray-100 p-6 shadow-md shadow-black/5 w-full mr-3'>
									<div class='flex justify-between'>
										<div>
											<Typography class='text-sm font-medium text-gray-400'>
												Inactive Clients
											</Typography>
											<Typography class='text-2xl font-semibold mt-3 text-gray-700'>
												{fetchedClientsCount && `${addComma(clientsCounts.inactive)}`}
											</Typography>
										</div>
										<div>
											<div class='flex items-center mt-5'>
												<div className='p-3 bg-gray-100 rounded-full'>
													<UserGroupIcon className='text-gray-400 h-10 w-10' />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class='bg-white rounded-lg border border-gray-100 p-6 shadow-md shadow-black/5 w-full mr-3'>
									<div class='flex justify-between'>
										<div>
											<Typography class='text-sm font-medium text-gray-400'>
												Dormant Clients
											</Typography>
											<Typography class='text-2xl font-semibold mt-3 text-gray-700'>
												{fetchedClientsCount && `${addComma(clientsCounts.dormant)}`}
											</Typography>
										</div>
										<div>
											<div class='flex items-center mt-5'>
												<div className='p-3 bg-slate-100 rounded-full'>
													<UserGroupIcon className='text-slate-500 h-10 w-10' />
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class='bg-white rounded-lg border border-gray-100 p-6 shadow-md shadow-black/5 w-full'>
									<div class='flex justify-between'>
										<div>
											<Typography class='text-sm font-medium text-gray-400'>
												Pending Clients
											</Typography>
											<Typography class='text-2xl font-semibold mt-3 text-gray-700'>
												{fetchedClientsCount && `${addComma(clientsCounts.pending)}`}
											</Typography>
										</div>
										<div>
											<div class='flex items-center mt-5'>
												<div className='p-3 bg-yellow-100 rounded-full'>
													<UserGroupIcon className='text-yellow-500 h-10 w-10' />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Grid>
						{/* Stats Bar Chart */}
						<Grid container spacing={2} style={{ margin: '0 5px 30px 5px' }}>
							<div class='px-6 py-2 w-full block lg:flex'>
								<div className='w-full mr-3'>
									<Stats
										packageAnalytics={packageAnalytics}
										setActiveYear={setActiveYear}
										activeYear={state.activeYear}
									/>
								</div>
								<div className='w-full'>
									<Stats1
										paymentAnalytics={paymentAnalytics}
										setActiveYear={setActiveYear1}
										activeYear={state.activeYear1}
									/>
								</div>
							</div>
						</Grid>
						{/* Latest */}
						<Grid container spacing={2} style={{ margin: '0 5px 30px 5px' }}>
							<div class='px-6 py-2 w-full block lg:flex h-full'>
								<div className='w-full mr-3'>
									<Card className={clsx(classes.root)}>
										<CardHeader title={<span style={{ fontSize: '16px' }}>Last 5 Signups</span>} />
										<Divider />
										<CardContent className={classes.content}>
											<PerfectScrollbar>
												<div className={classes.inner}>
													<Table>
														<TableHead>
															<TableRow>
																<TableCell>Client Number</TableCell>
																<TableCell>Client Name</TableCell>
																<TableCell sortDirection='desc'>
																	<Tooltip enterDelay={300} title='Sort'>
																		<TableSortLabel active direction='desc'>
																			Date
																		</TableSortLabel>
																	</Tooltip>
																</TableCell>
																<TableCell>Status</TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{clientsLatest &&
																clientsLatest.lastFiveClients &&
																clientsLatest.lastFiveClients.length > 0 &&
																clientsLatest.lastFiveClients.map(clnt => (
																	<TableRow hover key={clnt.userId}>
																		<TableCell>{clnt.id}</TableCell>
																		<TableCell>
																			{clnt.firstName} {clnt.lastName}
																		</TableCell>
																		<TableCell>
																			{clnt.createdOn &&
																				customDateFormatter(clnt.createdOn)}
																		</TableCell>
																		<TableCell>
																			<div
																				style={{
																					borderRadius: '8px',
																					background:
																						'rgba(240, 240, 240, .5)',
																					padding: '5px',
																				}}
																			>
																				<StatusBullet
																					className={classes.status}
																					color={statusColors[clnt.status]}
																					style={{ color: 'red' }}
																					size='sm'
																				/>
																				<span style={{ paddingLeft: '5px' }}>
																					{clnt.status}
																				</span>
																			</div>
																		</TableCell>
																	</TableRow>
																))}
														</TableBody>
													</Table>
												</div>
											</PerfectScrollbar>
										</CardContent>
										<Divider />
									</Card>
								</div>
								<div className='w-full'>
									<Card className={clsx(classes.root)}>
										<CardHeader title={<span style={{ fontSize: '16px' }}>Shipments</span>} />
										<Divider />
										<CardContent className={classes.content}>
											{clientsLatest && clientsLatest.lastPackage && (
												<div>
													<div className='pt-5'>
														<h2 className='text-2xl font-bold text-gray-700'>
															Last Shipment
														</h2>
														<p className='text-gray-400 text-lg'>
															{clientsLatest.lastPackage.trackingNumber}
														</p>
													</div>
													<div className='pt-3 text-lg text-gray-400'>
														<p className='pt-5 w-full flex justify-between'>
															<span>Tracking Number</span>
															<span>{clientsLatest.lastPackage.trackingNumber}</span>
														</p>
														<p className='pt-5 w-full flex justify-between'>
															<span>Status</span>
															<span className='bg-red-100 text-red-500 text-base font-semibold p-2 rounded-lg'>
																{formatPackageStatus(
																	clientsLatest.lastPackage.packageStatus
																)}
															</span>
														</p>
														<p
															style={{
																width: '100%',
																display: 'flex',
																justifyContent: 'space-between',
															}}
															className='pt-5'
														>
															<span>Date</span>
															<span>
															{clientsLatest.lastPackage &&
																	clientsLatest.lastPackage.createdOn &&
																	customDateFormatter(
																		clientsLatest.lastPackage.createdOn
																	)}
															</span>
														</p>
													</div>
												</div>
											)}
										</CardContent>
									</Card>
								</div>
							</div>
						</Grid>
					</Grid>
				</Paper>
				{state.dateError ? <HttpError errorMessage={state.dateError} /> : null}
				{adminHttpErrors ? <HttpError errorMessage={adminHttpErrors} /> : null}
				{adminHttpSuccess ? <HttpSuccess successMessage={adminHttpSuccess} /> : null}
			</div>
		</>
	);
};

export default OfficerStats;
